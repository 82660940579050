import { getCurrentOptions } from '../config/appConfig.js';

function template_marking( sMatchedText){

	return `<span class="u_word_dic" data-hook="tip" data-type="arken" data-lang="ko">${sMatchedText}</span>`;
}

const sKoreanPattern = "\uAC00-\uD7AF";
const defaultPattern = "[" + sKoreanPattern + "]";

const markerByLang = {
	_pattern: null,
	_pattern_legacy:null,
	sourceLang: "ko",

	getPattern: function () {
		let currentOptions =  getCurrentOptions();
		let minWordLength = currentOptions.lang["ko"].minWordLength;
		if (minWordLength === undefined) minWordLength = 1;
		//console.log(`[raw-refine-ko.js] getPattern, minWordLength = ${minWordLength}`);
		let sQuantifiersPattern = "{" + minWordLength + ",}";
		let sPattern = defaultPattern + sQuantifiersPattern;
		//console.log(`[raw-refine-ko.js] getPattern, sPattern = ${sPattern}`);
		this._pattern = new RegExp(sPattern, "g");
		return this._pattern;
	},
	getPattern_legacy:function () {
		let currentOptions =  getCurrentOptions();
		let minWordLength = currentOptions.lang["ko"].minWordLength;
		if (minWordLength === undefined) minWordLength = 1;
		let  sQuantifiersPattern = "{" + minWordLength + ",}";
		let sPattern = "(<a [^>]+>([\\s\\S]*?)<\/a>|<span class=\"u_word_dic[^>]+>([\\s\\S]*?)<\/span>|<script[^>]*>([\\s\\S]*?)<\/script>|<style[^>]*>([\\s\\S]*?)<\/style>|<[^>]+>|" + defaultPattern + sQuantifiersPattern + ")";

		this._pattern_legacy = new RegExp(sPattern, "g");
		return this._pattern_legacy;

	},
	mark: function (originalTextContent) {
		//console.log(`[raw-refine-ko.js] mark, original textContent = ${originalTextContent}`);
		let regExp = this._pattern || this.getPattern();
		let isMatched = false;
		let reformedTextContent = originalTextContent.replace(regExp, function (match,$1) {
			//let sMatchedText = match;
			isMatched = true;
			return template_marking(match);
		});
		//console.log(`[raw-refine-ko.js] mark, reformed text = ${reformedTextContent}`);
		return {
			textContent: reformedTextContent,
			isMatched: isMatched
		};
	},
	mark_legacy:function(sHtml){
		let oPattern = this._pattern_legacy || this.getPattern_legacy();
		sHtml = sHtml.replace(oPattern, function($1) {
			let sMatchedText = $1;
			if (sMatchedText.charAt(0) == '<' && sMatchedText.charAt(sMatchedText.length - 1) == '>') {
				return sMatchedText;
			}
			return template_marking(sMatchedText);
		});

		return sHtml;

	}
};

export default markerByLang;