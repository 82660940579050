export function escapeHtmlAttributes($ele) {

    var $eleList = $ele.find("*");
    var elementsLength = $eleList.length;
    //console.log(elementsLength);

    $eleList.each(function () {

        var attrs = this.attributes;
        var attrsLength = attrs.length;
        for (var index = 0; index < attrsLength; index++) {
            attrs[index].nodeValue = attrs[index].nodeValue.replace(/>/g, "&gt;").replace(/</g, "&lt;");
        }
    });


    // for (var eleIndex = 0; eleIndex < elementsLength; eleIndex++) {

    //     var attrs = elements[eleIndex].attributes;
    //     var attrsLength = attrs.length;

    //     for (var index = 0; index < attrsLength; index++ ) {
    //         attrs[index].nodeValue = attrs[index].nodeValue.replace(/>/g, "&gt;").replace(/</g, "&lt;");
    //     }
    // }
}