

const targetEnum = {

	'0':'_blank', //새창
	'1':'_self',  // 본창
	'2':'view_window' // 고정창
};


export function getTarget(layerTarget) {

	let default_idx = '1';
	return targetEnum[layerTarget] || targetEnum[default_idx];		
}

//module.exports = getTarget;