const i18nText = {
	source: {
		ko: '출처',
		en: 'Source',
		ja: '出処',
		zh: '出处',
		vi: 'Source',
		id: 'Source'
	},
	sourceLine: {
		ja: '出典',
		en: 'Source'
	},
	otherMeanings: {
		ko: (count) => `다른 뜻 <span class="u_bold_num">${count}</span>개 · `,
		en: (count) => `Other Meanings <span class="u_bold_num">${count}</span> · `,
		ja: (count) => `その他<span class="u_bold_num">${count}</span>件 · `,
		zh: (count) => `其他<span class="u_bold_num">${count}</span>个意思 · `,
		vi: (count) => `<span class="u_bold_num">${count}</span> nghĩa khác · `,
		id: (count) => `Definisi lain<span class="u_bold_num">${count}</span> · `,
	},
	allResults: {
		ko: '전체 검색 결과 보기 ',
		en: 'All Results ',
		ja: 'すべての検索結果 ',
		zh: '查看所有结果 ',
		vi: 'Tất cả các kết quả ',
		id: 'Semua hasil pencarian'
	},
	/*caution:{
		ko:'<span class="u_notice_inner">사전 결과 자동 추출로 오류가 있을 수 있습니다. </span>',
		en:'<span class="u_notice_inner">There may be errors due to auto-results. </span>',
		ja:'<span class="u_notice_inner">検索結果は自動的に表示されるため、結果に誤りがあることがあります。 </span>',
		zh:'<span class="u_notice_inner">词典自动筛选结果可能存在错误。 </span>',
		vi:'<span class="u_notice_inner">Có thể có các lỗi do kết quả tự động. </span>',
		id:'<span class="u_notice_inner">Eror dapat terjadi karena output otomatis hasil kamus. </span>'
	},*/
	caution: {
		ko: '사전 결과 자동 추출로 오류가 있을 수 있습니다. ',
		en: 'There may be errors due to auto-results. ',
		ja: '検索結果は自動的に表示されるため、結果に誤りがあることがあります。 ',
		zh: '词典自动筛选结果可能存在错误。 ',
		vi: 'Có thể có các lỗi do kết quả tự động. ',
		id: 'Eror dapat terjadi karena output otomatis hasil kamus. '
	},
	cautionLine: {
		ja: 'こちらの検索結果は自動的に<br>引き出されたため、<br>お探しの意味と異なる可能性があります。',
		en: 'There may be errors due to auto-results. '
	},
	reportButton: {
		ko: '신고하기',
		en: 'Report',
		ja: '報告する',
		zh: '投诉',
		vi: 'Báo cáo',
		id: 'Laporkan'
	},
	reportButtonLine: {
		ja: ' ヘルプ',
		en: 'Help'
	},
	multiTabNoResult: {
		ko: (sQuery, lang) => `"<span lang="${lang}">${sQuery}</span>"에 대한 검색결과가 없습니다.`,
		en: (sQuery, lang) => `No results for "<span lang="${lang}">${sQuery}</span>".`,
		ja: (sQuery, lang) => `No results for "<span lang="${lang}">${sQuery}</span>".`,
		zh: (sQuery, lang) => `没有"<span lang="${lang}">${sQuery}</span>"的搜索结果。`,
		vi: (sQuery, lang) => `No results for "<span lang="${lang}">${sQuery}</span>".`,
		id: (sQuery, lang) => `Tidak ada hasil tentang "<span lang="${lang}">${sQuery}</span>".`
	}
};

export default i18nText;
