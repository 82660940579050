import typeA from '../../views/type-a.hbs';
import typeBasic from '../../views/type-basic.hbs';
import typeC_zoom from '../../views/type-c-zoom.hbs';
import typeC from '../../views/type-c.hbs';
import typeD from '../../views/type-d.hbs';

import greendot_typeA from '../../views/greendot/type-a.hbs';
import greendot_typeBasic from '../../views/greendot/type-basic.hbs';
import greendot_typeC_zoom from '../../views/greendot/type-c-zoom.hbs';
import greendot_typeC_zoom_hanja from '../../views/greendot/type-c-zoom-hanja.hbs';
import greendot_typeC from '../../views/greendot/type-c.hbs';
import greendot_typeC_hanja from '../../views/greendot/type-c-hanja.hbs';
import greendot_typeD from '../../views/greendot/type-d.hbs';
import greendot_typeD_line from '../../views/greendot/type-d-line.hbs';
import greendot_typeE from '../../views/greendot/type-e.hbs';
import greendot_typeF from '../../views/greendot/type-f.hbs';

import {
	getTarget
} from './config/layerTarget.js';
import {
	getCurrentOptions
} from './config/appConfig';
var templateConfig = {
	"greendot": {
		"ko": {
			"ko": greendot_typeA,
			"en": greendot_typeBasic,
			"zh": greendot_typeBasic,
			"vi": greendot_typeBasic,
			"ja": greendot_typeA
		},
		"en": {
			"ko": greendot_typeA,
			"en": greendot_typeA,
			"ja": greendot_typeA,
			"id": greendot_typeA,
			"ar": greendot_typeA,
			"de": greendot_typeA,
			"es": greendot_typeA,
			"fr": greendot_typeA,
			"pt": greendot_typeA,
			"ru": greendot_typeA,
			"vi": greendot_typeA,
			"zh": greendot_typeA,
			"th": greendot_typeA
		},
		"zh": {
			"ko": greendot_typeA
		},
		"hj": {
			"ko": greendot_typeC
		},
		'cc': {
			"ko": greendot_typeC_hanja
		},
		"hjzoom": {
			"hj": greendot_typeC_zoom_hanja, //한자사전
			'terms': greendot_typeC_zoom
		},
		"ja": {
			"ko": greendot_typeD,
			"ja": greendot_typeD_line
		},
		"scroll": greendot_typeF,
		'noResult': {
			'ko': greendot_typeE
		}
	},
	"ko": {
		"ko": typeA,
		"en": typeBasic,
		"zh": typeBasic,
		"vi": typeBasic,
		"ja": typeA
	},
	"en": {
		"ko": typeA,
		"en": typeA,
		"id": typeA
	},
	"zh": {
		"ko": typeA
	},
	"hj": {
		"ko": typeC
	},
	"hjzoom": typeC_zoom, //지식백과
	"ja": {
		"ko": typeD
	}
};

export function getTermsZoomLayer({
	layerType,
	hanjaList
}) {
	//console.log(`[layers.js] getTermsZoomLayer, layerType = ${layerType}`);
	let hanjaArray = [...hanjaList];
	let body = {
		hanjaArray
	};
	let template = templateConfig[layerType] && templateConfig[layerType]['hjzoom'];
	if (!template) {
		//console.log(`[layers.js] getTermsZoomLayer, get typeC_zoom`);
		template = templateConfig['hjzoom'];
	}

	let str = '';
	if (template) {
		str = template(body);
	}

	return str;
}

export function getHanjaZoomLayer({
	layerType,
	hanjaList,
	quantumIndex
}) {
	//console.log(`[layers.js] getHanjaZoomLayer, layerType = ${layerType}, quantumIndex = ${quantumIndex}`);
	let hanjaArray = [...hanjaList];
	let body = {};
	body.hanjaArray = regroupHanjaArray(hanjaArray, quantumIndex, body);
	let template = templateConfig.greendot.hjzoom.hj;

	let str = '';
	if (template) {
		str = template(body);
	}

	return str;
}

function regroupHanjaArray(hanjaArray, wordIndex, body) {
	let currentOptions = getCurrentOptions();
	currentOptions.hanjaZoomArray = hanjaArray;
	//console.log(`regroupHanjaArray`);
	let newArray = null;
	if (hanjaArray.length < 8) {
		currentOptions.hanjaZoomStartIndex = 0;
		newArray = hanjaArray;
	} else {
		if (wordIndex <= 3) {
			currentOptions.hanjaZoomStartIndex = 0;
			newArray = hanjaArray.slice(0, 7);
			body.zoomHasPrev = false;
			body.zoomHasNext = true;
		} else if (wordIndex < hanjaArray.length - 4) {
			currentOptions.hanjaZoomStartIndex = wordIndex - 3;
			newArray = hanjaArray.slice(currentOptions.hanjaZoomStartIndex, currentOptions.hanjaZoomStartIndex + 7);
			body.zoomHasPrev = true;
			body.zoomHasNext = true;

		} else if (wordIndex === hanjaArray.length - 4) {
			currentOptions.hanjaZoomStartIndex = wordIndex - 3;
			newArray = hanjaArray.slice(currentOptions.hanjaZoomStartIndex, currentOptions.hanjaZoomStartIndex + 7);
			body.zoomHasPrev = true;
			body.zoomHasNext = false;
		} else {
			currentOptions.hanjaZoomStartIndex = hanjaArray.length - 7;
			newArray = hanjaArray.slice(currentOptions.hanjaZoomStartIndex);
			body.zoomHasPrev = true;
			body.zoomHasNext = false;
		}
	}
	return newArray;

}

export function getNoResultLayer(layerType, targetLang) {
	let templateString = '';
	let template = templateConfig[layerType].noResult[targetLang];
	if (template) {
		templateString = template()
	}
	return templateString;
}
export default function({
	layerType,
	sourceLang,
	targetLang,
	body,
	userCode,
	layerTarget,
	device,
	skin,
	prCode
}) {
	let currentOptions = getCurrentOptions();
	let template = templateConfig[layerType] && templateConfig[layerType][sourceLang] && templateConfig[layerType][sourceLang][targetLang];
	if (currentOptions.useScrollLayer) {
		template = templateConfig[layerType] && templateConfig[layerType]["scroll"];
	}
	if (!template) { // no specific layerType or wrong layerType
		template = templateConfig[sourceLang][targetLang];
	}
	//console.log(`[layers.js] getLayer, sourceLang = ${sourceLang}, targetLang =${targetLang}`);
	//console.log(`[layers.js] body.result.moreDetailUrl = ${body.result.moreDetailUrl}`);
	let location = window.location;
	//console.log(`[layers.js] location.href = ${location.href}, location.origin = ${location.origin}, location.pathname = ${location.pathname}, location.hash=${location.hash}`);
	if (location.host.indexOf("dict.naver.com") !== -1 && location.hash !== "" && location.hash.indexOf("#/mini/") === 0) {
		body.result.moreDetailUrl = "#" + body.result.moreDetailUrl.split("#")[1];
	}
	let templateString = '';
	if (template && body.result) {
		if (sourceLang !== 'cc') {
			let nsc = currentOptions['lang'][sourceLang]['nsc'] || "";
			//console.log(`[layers.js] getLayer, nsc = ${nsc}`);
			body.isGlobalEnen = nsc === 'dic.tt.eee' || nsc === 'mdic.tt.eee';
			body.isEnxx = nsc.indexOf('mdic.tt.en') > -1 || nsc.indexOf('dic.tt.en') > -1 || nsc === 'dic.tt.iei' || nsc === 'mdic.tt.iei'; //是否英外词典（没有包括enko)
			body.skin = skin;
			body.isNews = nsc === 'dic.tt.nek' || nsc === 'mdic.tt.nek'; //是否是news服务
		}
		body.isLine = (prCode === 'linedict');
		body.userCode = skin || userCode; // for guid message
		body.device = device || 'm';
		body.layerTarget = getTarget(layerTarget);
		if (currentOptions.multiTab) {
			if (currentOptions.multiTab instanceof Array) {
				if (Object.keys(currentOptions.lang).length == 1) {
					body.multiTab = currentOptions.multiTab;
				}
			} else {
				if (currentOptions.multiTab[sourceLang]) {
					body.multiTab = currentOptions.multiTab[sourceLang];
				}
			}
		}
		if (currentOptions.isNaverDictAppAccentia) {
			body.isNaverDictAppAccentia = currentOptions.isNaverDictAppAccentia;
		}
		//console.log(`[layers.js] sourceLang = ${sourceLang}, targetLang = ${targetLang}, userCode = ${userCode}, device = ${device}, layerTarget=${body.layerTarget}`);
		templateString = template(body);
	}
	return templateString;
};
