
import $ from 'jQuery';
import trimQuery from './trim-query.js';
import { getCurrentOptions } from '../config/appConfig';


export function position_nospacelang(event){
    //console.log(`[search-neighbor.js] position_nospacelang`);
    var $target = $(event.target);

    var $directParent = $target.parent();
    var clickedIdx = $directParent.children().index($target);

    var raw = $directParent.text();
    //event.target = $directParent.get(0); // replace child with parent
    return {
        raw,
        clickedIdx
    };
}

export default function (event) {
	//based on event target, get the presumed sentence including it.
	//console.log(`[search-neighbor.js] default`);
	let $target = $(event.target);
	// 먼저 parent를 찾는다.
	let lang = $target.attr('data-lang');  //
	//let childs = 'span[data-type="ore"]';
	//if(lang){
	//	childs = childs+'[data-lang="'+lang+'"]';
	//}
	//let $utMostParent = _getUtmostParent($target.parent());
	//parent의 모든 직속 자식과 손자들을 찾는다. recursively
	//console.log(`childs = ${childs}`);
	//let $eleList = $utMostParent.find(childs);

	let $eleList = null;
	if (lang === 'ja') {
		$eleList = $(generateQuerySentenceJapanese(event.target,lang));
	} else {
		$eleList = $(generateQuerySentence(event.target,lang));
	}


	let tIdx = $eleList.index($target);
	//console.log(`before ${tIdx}`);

	// the bigger request size, the bigger request url size because of GET
	//so, I constrain the request size to moderate
	$eleList = $eleList.filter(function(index){
		return Math.abs(index-tIdx) <= 90;
	});

	/*$eleList = $eleList.filter(function(element,index){
		console.log(`index = ${index}`);
		return Math.abs(index-tIdx) <= 90;
	});*/

	// console.log('elelist size');
	//console.log($eleList.size());

	//recalculate idx of target in element list
	tIdx = $eleList.index($target);
	
	// console.log(`after ${tIdx}`);
	let raw = getRawSentence($eleList,lang);
	//console.log(`[search-neighbor.js] raw = ${raw}`);

	return { $eleList, raw, clickedIdx: tIdx};
}

function generateQuerySentence(eventTargetElement,lang) {
	let targetElement = eventTargetElement;
	let elementList = [];
	let regexOfFormattingTagName = /^(?:STRONG|EM|SMALL|MARK|DEL|I|B|S|U)$/g;

    elementList.push(targetElement);
	let isFormattingTag = regexOfFormattingTagName.test(targetElement.parentNode.tagName);
	if(isFormattingTag) {
        findPreviousWord(targetElement.previousSibling,elementList,lang);
		findPreviousWord(targetElement.parentNode.previousSibling,elementList,lang);
        findNextWord(targetElement.nextSibling,elementList,lang);
		findNextWord(targetElement.parentNode.nextSibling,elementList,lang);
	} else {
		findPreviousWord(targetElement.previousSibling,elementList,lang);
		findNextWord(targetElement.nextSibling,elementList,lang);
	}

	//console.log(`[search-neighbor.js] generateQuerySentence, elementList.length =${elementList.length}`);
	return elementList;
}

function findPreviousWord(targetNode, elementList, lang) {
	//console.log(`[search-neighbor.js] findPreviousWord, targetNode.nodeValue = ${targetNode?targetNode.nodeValue: 'null'}`);
	let regexOfFormattingTagName = /^(?:STRONG|EM|SMALL|MARK|DEL|I|B|S|U)$/g;
	if (targetNode && targetNode.nodeType === Node.ELEMENT_NODE) {
		if(targetNode.getAttribute('data-type') ==='ore' && targetNode.getAttribute('data-lang') === lang) {
			elementList.unshift(targetNode);
			findPreviousWord(targetNode.previousSibling,elementList,lang);
		} else if(regexOfFormattingTagName.test(targetNode.tagName)){
			let lastChildOfFormattingNode = targetNode.childNodes[targetNode.childNodes.length-1];
			findPreviousWord(lastChildOfFormattingNode,elementList,lang);
			findPreviousWord(targetNode.previousSibling,elementList,lang);
		} else if(targetNode.tagName === 'SUB' || targetNode.tagName === 'SUP') {
			//console.log(`[search-neighbor.js] findPreviousWord, targetNode.tagName = ${targetNode.tagName}`);
			findPreviousWord(targetNode.previousSibling,elementList,lang);
		}
	} else if(targetNode && targetNode.nodeType === Node.TEXT_NODE) {
		if(targetNode.nodeValue.trim() === '') {
			findPreviousWord(targetNode.previousSibling,elementList,lang);
		}
	}
}

function findNextWord(targetNode,elementList,lang) {
	let regexOfFormattingTagName = /^(?:STRONG|EM|SMALL|MARK|DEL|I|B|S|U)$/g;
	if (targetNode && targetNode.nodeType === Node.ELEMENT_NODE) {
		if(targetNode.getAttribute('data-type') ==='ore' && targetNode.getAttribute('data-lang') === lang) {
			elementList.push(targetNode);
			findNextWord(targetNode.nextSibling,elementList,lang);
		} else if(regexOfFormattingTagName.test(targetNode.tagName)){
			let firstChildOfFormattingNode = targetNode.childNodes[0];
			findNextWord(firstChildOfFormattingNode,elementList,lang);
			findNextWord(targetNode.nextSibling,elementList,lang);
		} else if(targetNode.tagName === 'SUB' || targetNode.tagName === 'SUP') {
			//console.log(`[search-neighbor.js] findNextWord, targetNode.tagName = ${targetNode.tagName}`);
			findNextWord(targetNode.nextSibling,elementList,lang);
		}
	} else if(targetNode && targetNode.nodeType === Node.TEXT_NODE) {
		if(targetNode.nodeValue.trim() === '') {
			findNextWord(targetNode.nextSibling,elementList,lang);
		}
	}
}

function generateQuerySentenceJapanese(eventTargetElement,lang) {
	let targetElement = eventTargetElement;
	let elementList = [];
	let regexOfFormattingTagName = /^(?:STRONG|EM|SMALL|MARK|DEL|I|B|S|U)$/g;

	elementList.push(targetElement);
	let isFormattingTag = regexOfFormattingTagName.test(targetElement.parentNode.tagName);
	if(isFormattingTag) {
		findPreviousWordJapanese(targetElement.previousSibling,elementList,lang);
		findPreviousWordJapanese(targetElement.parentNode.previousSibling,elementList,lang);
		if (targetElement.parentNode.parentNode.tagName === "RB") {
			findPreviousWordJapanese(targetElement.parentNode.parentNode.parentNode.previousSibling,elementList,lang);
		}
		findNextWordJapanese(targetElement.nextSibling,elementList,lang);
		findNextWordJapanese(targetElement.parentNode.nextSibling,elementList,lang);
		if (targetElement.parentNode.parentNode.tagName === "RB") {
			findNextWordJapanese(targetElement.parentNode.parentNode.parentNode.nextSibling,elementList,lang);
		}
	} else if (targetElement.parentNode.tagName === "RB") {
		findPreviousWordJapanese(targetElement.previousSibling,elementList,lang);
		findPreviousWordJapanese(targetElement.parentNode.parentNode.previousSibling,elementList,lang);
		findNextWordJapanese(targetElement.nextSibling,elementList,lang);
		findNextWordJapanese(targetElement.parentNode.parentNode.nextSibling,elementList,lang);
	} else {
		findPreviousWordJapanese(targetElement.previousSibling,elementList,lang);
		findNextWordJapanese(targetElement.nextSibling,elementList,lang);
	}

	//console.log(`[search-neighbor.js] generateQuerySentence, elementList.length =${elementList.length}`);
	return elementList;
}

function findPreviousWordJapanese(targetNode, elementList, lang) {
	let regexOfFormattingTagName = /^(?:STRONG|EM|SMALL|MARK|DEL|I|B|S|U)$/g;
	if (targetNode && targetNode.nodeType === Node.ELEMENT_NODE) {
		if(targetNode.getAttribute('data-type') ==='ore' && targetNode.getAttribute('data-lang') === lang) {
			elementList.unshift(targetNode);
			findPreviousWordJapanese(targetNode.previousSibling,elementList,lang);
		} else if(regexOfFormattingTagName.test(targetNode.tagName)){
			let lastChildOfFormattingNode = targetNode.childNodes[targetNode.childNodes.length-1];
			findPreviousWordJapanese(lastChildOfFormattingNode,elementList,lang);
			findPreviousWordJapanese(targetNode.previousSibling,elementList,lang);
		} else if (targetNode.tagName === "RUBY") {
			//console.log(`[search-neighbor.js] findPreviousWordJapanese, RUBY node, targetNode.nodeValue = ${targetNode.nodeValue}`);
			let rbNode = targetNode.childNodes[0];
			let lastChildOfRbNode = rbNode.childNodes[rbNode.childNodes.length-1];
			findPreviousWordJapanese(lastChildOfRbNode,elementList,lang);
			findPreviousWordJapanese(targetNode.previousSibling,elementList,lang);
		}
	}
}

function findNextWordJapanese(targetNode,elementList,lang) {
	let regexOfFormattingTagName = /^(?:STRONG|EM|SMALL|MARK|DEL|I|B|S|U)$/g;
	if (targetNode && targetNode.nodeType === Node.ELEMENT_NODE) {
		if(targetNode.getAttribute('data-type') ==='ore' && targetNode.getAttribute('data-lang') === lang) {
			elementList.push(targetNode);
			findNextWordJapanese(targetNode.nextSibling,elementList,lang);
		} else if(regexOfFormattingTagName.test(targetNode.tagName)){
			let firstChildOfFormattingNode = targetNode.childNodes[0];
			findNextWordJapanese(firstChildOfFormattingNode,elementList,lang);
			findNextWordJapanese(targetNode.nextSibling,elementList,lang);
		} else if (targetNode.tagName === "RUBY") {
			let rbNode = targetNode.childNodes[0];
			let firstChildOfRbNode = rbNode.childNodes[0];
			findNextWordJapanese(firstChildOfRbNode,elementList,lang);
			findNextWordJapanese(targetNode.nextSibling,elementList,lang);
		}
	}
}

function _getUtmostParent($parent) {
	let rootSelector = getCurrentOptions().contentSelector;
	let regexOfFormattingTagName = /^(?:STRONG|EM|SMALL|MARK|DEL|I|B|S|U)$/g;
	if ($parent.is($(rootSelector))) {
		return $parent;
	} else if ($parent.parent() && $parent.parent().children('span[data-type="ore"]').length > 0) {
		// <span data-type="ore"/>인  삼촌이 존재한다. 그렇다면 위로 올라가라
		let $utMostParent = _getUtmostParent($parent.parent());
		return $utMostParent;
	} else {
		return $parent;
	}
}

function getRawSentence($eleList,lang) {
	//console.log(`[search-neighbor.js] getRawSentence, $eleList.length = ${$eleList.length}`);
	let textList = [];

	$eleList.each(function () {

		let txt = $(this).text();
		//console.log(`[search-neighbor.js] getRawSentence, text = ${txt}`);
		if (this.parentNode === null) {
			console.log(`[search-neighbor.js] getRawSentence, parent is null`);
		}
		// 앞끝과 뒤끗의 ?!.,들을 제거
		//textList.push(trimQuery(txt));
		textList.push(txt);

	});
	if (lang === 'ja') {
		return textList.join('').trim();
	} else {
		return textList.join(' ').trim();
	}
}