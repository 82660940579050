
import Marker from './marker.js';
import $ from 'jQuery';
import {escapeHtmlAttributes} from './logic/legacy-parsing';
import {getGeneralTextElements} from "./Util/domNodeUtil";

export default function Converter() {
	this.name = 'Converter';
	this._marker = null;

	this.init = function (rawRefiners) {
	    //console.log(`[converter.js] this.init`);
		this._marker = new Marker(rawRefiners);
	};

	this.convertGeneralLanguage = function (contentSelector) {
		//console.log(`[converter.js] this.convertGeneralLanguage`);
		const self = this;
		let targetList = $(contentSelector);
		let i =0;
		targetList.each(function(index){ // 지정 구역 대하여

			i++;
			let previous= $(this).attr("style")?($(this).attr("style")+";"):"";
			$(this).attr("style", previous + "-webkit-tap-highlight-color: rgba(0,0,0,0)");
			
            let it = getGeneralTextElements(this);// raw maker

            while (it.hasNext()) { //모든 raw에 대하여
                let textNode = it.next();  //return text node
				//console.log(`[convert.js] textNode.textContent = ${textNode.textContent}`);
                // raw handler
                let result = self._marker.mark(textNode.textContent);  //return object {textContent, isMatched}

                try {
                    if (result.isMatched) {
                        // console.log(result.reformedContent);
                        let html = $.parseHTML(result.reformedContent);
                        let parentNode = textNode.parentNode;
                        for (let j=0;j<html.length;j++) {
                            let newNode = document.importNode(html[j],true);
                            parentNode.insertBefore(newNode,textNode);
                        }
                        parentNode.removeChild(textNode);
                        /*let newNode = document.importNode(html,true);
                        $(textNode).before(newNode);
                        $(textNode).remove();*/
                    }
                } catch (err) {
                    console.log(err.message + "\n툴팁 대상에 오류가 있습니다. 아래 툴팁 대상이 올바른지 확인해보시기 바랍니다. \nerror convertGeneralLanguage target:" + textNode);
                }
            }
		});
	};

	this.convert_legacy = function (contentSelector) {
        //console.log(`[converter.js] this.convert_legacy`);
		let self = this;
		let targetList = $(contentSelector);
		targetList.each(function (index) { // 지정 구역 대하여
			let previous = $(this).attr("style") ? ($(this).attr("style") + ";") : "";
			$(this).attr("style", previous + "-webkit-tap-highlight-color: rgba(0,0,0,0)");
			// console.time("parse whole");
			escapeHtmlAttributes($(this));
			let sHtml = $(this).html();
			sHtml = self._marker.mark_legacy(sHtml);
			try{
				$(this).html(sHtml);
			}catch(err){
				console.log(err.message + "\n툴팁 대상에 오류가 있습니다. 아래 툴팁 대상이 올바른지 확인해보시기 바랍니다. \nerror convert_legacy target:" + this);
			}

			// console.timeEnd("parse whole");
		});
	}
};
