export default function(a, b, options) {
    if (arguments.length === 3) {
        if(a > b){
            return options.fn(this);
        }else{
            return options.inverse(this)
        }
    }else{
        return options.inverse(this)
    }
  };