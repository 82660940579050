import { getCurrentOptions } from '../config/appConfig.js';
//import rewritePattern from 'regexpu-core'

const sPatternCJKUnifiedIdeographs = "\u4E00-\u9FEF";	// 한중일 공통한자
const sPatternCJKCompatibilityIdeographs = "\uF900-\uFAD9";	// 한중일 호환
const sPatternCJKCompatibilityIdeographsSupplement = "\u{2F800}-\u{2FA1D}"; // 한중일 호환 확장 U+2F800 - U+2FA1D
//const sPatternCJKCompatibilityIdeographsSupplement = "\uD87E\uDC00-\uD87E\uDE1D"; // 한중일 호환 확장 U+2F800 - U+2FA1D
const sPatternKangxiRadicals = "\u2F00-\u2FDF";	// 강희자전 부수
const sPatternCJKRadicalsSupplement = "\u2E80-\u2EF3";	// 부수 확장
const sPatternCJKUnifiedIdeographsExtensionA = "\u3400-\u4DBF";	// 한자확장A
const sPatternCJKUnifiedIdeographsExtensionB = "\u{20000}-\u{2A6D6}";	// 한자확장B U+20000-U+2A6D6
//const sPatternCJKUnifiedIdeographsExtensionB = "\uD840\uDC00-\uD869\uDED6";	// 한자확장B U+20000-U+2A6D6

const defaultPattern = "[" +
	sPatternCJKUnifiedIdeographs +
	sPatternCJKCompatibilityIdeographs +
	sPatternCJKCompatibilityIdeographsSupplement +
	sPatternKangxiRadicals +
	sPatternCJKRadicalsSupplement +
	sPatternCJKUnifiedIdeographsExtensionA +
	sPatternCJKUnifiedIdeographsExtensionB +
	"]";
//ie11에서 regular expression unicode flag 'u'지원하지 않기에
const REGEX_CHINESE_single = /(?:[\u2E80-\u2EF3\u2F00-\u2FDF\u3400-\u4DBF\u4E00-\u9FEF\uF900-\uFAD9]|[\uD840-\uD868][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6]|\uD87E[\uDC00-\uDE1D]){1}/g;
const REGEX_CHINESE_multi = /(?:[\u2E80-\u2EF3\u2F00-\u2FDF\u3400-\u4DBF\u4E00-\u9FEF\uF900-\uFAD9]|[\uD840-\uD868][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6]|\uD87E[\uDC00-\uDE1D])+/g;

function templateZoomLayerMarking(sMatchedText) {
	return `<span class="u_word_dic" data-hook="tip" data-type="arken" data-lang="hj">${singleHanja_marking(sMatchedText)}</span>`;
}
function templateNormalMarking(sMatchedText) {
	return `<span class="u_word_dic" data-hook="tip" data-type="arken" data-lang="hj">${sMatchedText}</span>`;
}
function template_marking(sMatchedText) {
	let currentOptions =  getCurrentOptions();
	//console.log(`[raw-refine-hj.js] template_marking, sMatchedText=${sMatchedText}`);
	if(currentOptions.lang["hj"].zoomLayer && (currentOptions['isMobile'] || currentOptions.layerType === 'greendot')) {
		//grouping only if hanja and mobile
		return `<span class="u_word_dic" data-hook="tip" data-type="arken" data-lang="hj">${singleHanja_marking(sMatchedText)}</span>`;
	}
	return `<span class="u_word_dic" data-hook="tip" data-type="arken" data-lang="hj">${sMatchedText}</span>`;
}
export function singleHanja_marking(sMatchedText=''){

	//console.log(`[raw-refine-hj.js] singleHanja_marking, sMatchedText = ${sMatchedText}`);
	let tagArray = [...sMatchedText].map(function(hanja){
		return `<span data-type='quant'>${hanja}</span>`;
	});
	return tagArray.join('');
}

const markerByLang = {
	
	_pattern: null,
	_pattern_legacy:null,
	sourceLang: "hj",

	getPattern() {
		let currentOptions =  getCurrentOptions();
		//let sQuantifiersPattern = "{1}";// 1글자씩만 고정 탐색
		//let sQuantifiersPattern = "{1,}";// 문장단위 탐색
		//let rewritePatternString = rewritePattern(defaultPattern + sQuantifiersPattern,'gu');
		//this._pattern = new RegExp(defaultPattern + sQuantifiersPattern,'gu');
		if (currentOptions.lang.hj.nsc === 'dic.tt.cck' || currentOptions.lang.hj.nsc === 'mdic.tt.cck') {
			this._pattern = REGEX_CHINESE_multi;
		} else {
			this._pattern = REGEX_CHINESE_single;
		}
		//console.log(`[raw-refine-hj.js] this._pattern = ${this._pattern}`);
		return this._pattern;
	},
	getPattern_legacy() {
		let currentOptions =  getCurrentOptions();
		/*let sQuantifiersPattern = "{1}";// 1글자씩만 고정 탐색
		if(currentOptions.lang["hj"].zoomLayer && currentOptions['isMobile']) { //grouping only if hanja and mobile
			sQuantifiersPattern = "{1,}";
		}*/
		if (currentOptions.lang.hj.nsc === 'mdic.tt.thk') {
			//this._pattern_legacy = REGEX_CHINESE_multi;
			this._pattern_legacy = /(<a (?:(?!>)[\s\S])+>((?:[\0-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])*?)<\/a>|<span class="u_word_dic(?:(?!>)[\s\S])+>((?:[\0-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])*?)<\/span>|<script(?:(?!>)[\s\S])*>((?:[\0-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])*?)<\/script>|<style(?:(?!>)[\s\S])*>((?:[\0-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])*?)<\/style>|<(?:(?!>)[\s\S])+>|(?:[\u2E80-\u2EF3\u2F00-\u2FDF\u3400-\u4DBF\u4E00-\u9FEF\uF900-\uFAD9]|[\uD840-\uD868][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6]|\uD87E[\uDC00-\uDE1D])+)/g;
		} else {
			this._pattern_legacy = REGEX_CHINESE_single;
		}
		/*let sPattern = "(<a [^>]+>([\\s\\S]*?)<\/a>|<span class=\"u_word_dic[^>]+>([\\s\\S]*?)<\/span>|<script[^>]*>([\\s\\S]*?)<\/script>|<style[^>]*>([\\s\\S]*?)<\/style>|<[^>]+>|" + defaultPattern + sQuantifiersPattern + ")";
		let plainPattern = rewritePattern(sPattern,'gu');
		console.log(`[raw-refine-hj.js] plainPattern = ${plainPattern}`);
		this._pattern_legacy = new RegExp(sPattern, "gu");*/
		//console.log(`[raw-refine-hj.js] this._pattern_legacy = ${this._pattern_legacy}`);
		return this._pattern_legacy;
	},
	mark(originalTextContent) {
		//console.log(`[raw-refine-hj.js] mark, original textContent = ${originalTextContent}`);
		let regExp = this._pattern || this.getPattern();
		let isMatched = false;

		let zoomLayerMarking = function (match, $1) {
			//console.log(`zoomLayerMarking, match = ${match}`);
			isMatched = true;
			return templateZoomLayerMarking(match);
		};

		let normalMarking = function (match, $1) {
			//console.log(`normalMarking, match = ${match}`);
			isMatched = true;
			return templateNormalMarking(match);
		};

		/*for (let i = 0; i< originalTextContent.length; i++) {
			let charCode = originalTextContent[i].charCodeAt(0);
			if (charCode >= 0xD800 && charCode <= 0xDBFF) {
				let charCode1 = charCode.toString(16);
				console.log(`Bytes count in Unicode = 4, charCode16 = ${charCode1}`);
			} else {
				let charCode2 = charCode.toString(16);
				console.log(`正常, charCode16 = ${charCode2}`);
			}
		}*/

		let reformedTextContent = '';
		let currentOptions =  getCurrentOptions();
		if (currentOptions.lang["hj"].zoomLayer && (currentOptions['isMobile'] || currentOptions.layerType === 'greendot')) {
			//grouping only if hanja zoomLayer and mobile
			reformedTextContent = originalTextContent.replace(regExp,zoomLayerMarking);
		} else {
			reformedTextContent = originalTextContent.replace(regExp,normalMarking);
		}

		/*reformedTextContent = originalTextContent.replace(regExp, function ($1) {
			let sMatchedText = $1;
			isMatched = true;
			return template_marking(sMatchedText);
		});*/
		//console.log(`[raw-refine-hj.js] mark, reformed text = ${reformedTextContent}`);
		return {
			textContent: reformedTextContent,
			isMatched: isMatched
		};
	},
	mark_legacy(sHtml){
		let oPattern = this._pattern_legacy || this.getPattern_legacy();
		sHtml = sHtml.replace(oPattern, function($1) {
			let sMatchedText = $1;
			if (sMatchedText.charAt(0) == '<' && sMatchedText.charAt(sMatchedText.length - 1) == '>') {
				return sMatchedText;
			}
			return template_marking(sMatchedText);
		});

		return sHtml;
	}
};

export default markerByLang;