var Handlebars = require("../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <dl class=\"u_helpdict_area\">\n            <dt class=\"u_headword\">\n                <a data-nclick=\"tot.entry\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"destinationLink") : depth0), depth0))
    + "\" class=\"u_btn_headword\" target=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"layerTarget") : depths[1]), depth0))
    + "\">\n                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"entryName") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"kanji") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":10,"column":27}}})) != null ? stack1 : "")
    + "                </a>\n            </dt>\n            <dd class=\"u_pronunciation\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"pronounceList") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":26,"column":25}}})) != null ? stack1 : "")
    + "            </dd>\n\n            <dd class=\"u_definition\">\n                <ul class=\"u_mean_word\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"partOfSpeechList") : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":45,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"source") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":49,"column":23}}})) != null ? stack1 : "")
    + "            </dd>\n        </dl>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"u_origin_word\">["
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"kanji") : depth0), depth0)) != null ? stack1 : "")
    + "]</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"u_pronunciation_item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"pronunceUrl") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":24,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"partOfSpeech") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":19,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sign") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":22,"column":35}}})) != null ? stack1 : "")
    + "                            <button data-nclick=\"tot.tts\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tts") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":23,"column":65},"end":{"line":23,"column":112}}})) != null ? stack1 : "")
    + "\" data-url=\""
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"pronunceUrl") : depth0), depth0)) != null ? stack1 : "")
    + "\">발음듣기</button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"u_by_country\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"partOfSpeech") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"u_phonetic_alphabet\">["
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"sign") : depth0), depth0)) != null ? stack1 : "")
    + "]</span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "u_btn_tts";
},"12":function(container,depth0,helpers,partials,data) {
    return "u_btn_pronun";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../public/src/handlebars-helpers/gt.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"meaningList") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),1,{"name":"gt","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":44,"column":31}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"u_mean_word_item u_list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"meaningList") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":28},"end":{"line":36,"column":37}}})) != null ? stack1 : "")
    + "                        </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"u_word_mean\"><span class=\"u_words_num\">"
    + container.escapeExpression(__default(require("../public/src/handlebars-helpers/add.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"index")),1,{"name":"add","hash":{},"data":data,"loc":{"start":{"line":35,"column":84},"end":{"line":35,"column":100}}}))
    + ".&nbsp;</span>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"desciption") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"u_mean_word_item\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"meaningList") : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":32},"end":{"line":42,"column":41}}})) != null ? stack1 : "")
    + "                            </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <span class=\"u_word_mean\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"desciption") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"u_origin_source\">"
    + ((stack1 = __default(require("../public/src/handlebars-helpers/msg.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"source",(depths[1] != null ? lookupProperty(depths[1],"userCode") : depths[1]),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":48,"column":50},"end":{"line":48,"column":80}}})) != null ? stack1 : "")
    + ": "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"source") : depth0), depth0))
    + "</span>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"u_relate_hanja\">관련 한자</div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <dl class=\"u_helpdict_area u_hanja\">\n            <dt class=\"u_headword\">\n                <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"destinationLink") : depth0), depth0))
    + "\" class=\"u_btn_headword\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"entry") : depth0), depth0))
    + "</a>\n                <span class=\"u_han_mean\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"koreanPron") : depth0), depth0))
    + "</span>\n            </dt>\n            <dd class=\"u_definition\">\n                <ul class=\"u_mean_word\">\n                    <li class=\"u_mean_word_item\">\n                        <span class=\"u_hanja_option\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hanjaRadical") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":28},"end":{"line":70,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hanjaStroke") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":28},"end":{"line":73,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                        <div class=\"u_japan_option\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"audioRead") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":28},"end":{"line":80,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"meaningRead") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":28},"end":{"line":85,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </li>\n                </ul>\n            </dd>\n        </dl>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"u_option_item\">부수</span>\n                                <span class=\"u_option_radical\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"hanjaRadical") : depth0), depth0))
    + "</span>\n                                ("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"hanjaRadicalStroke") : depth0), depth0))
    + "획)\n";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"u_option_item\">총획</span>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"hanjaStroke") : depth0), depth0))
    + "획\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"u_read_item\">\n                                    <div class=\"read_item\">음독</div><div class=\"read_text\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"audioRead") : depth0), depth0))
    + "</div>\n                                </span>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"u_read_item\">\n                                    <div class=\"read_item\">훈독</div><div class=\"read_text\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"meaningRead") : depth0), depth0))
    + "</div>\n                                </span>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../public/src/handlebars-helpers/otherMeanings.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"moreItem") : stack1),{"name":"otherMeanings","hash":{},"data":data,"loc":{"start":{"line":94,"column":146},"end":{"line":94,"column":190}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"tooltipLayer_dict\" class=\"u_helpdict_module u_type_japan\" style=\"\">\n    <audio id='tip_audio' style=\"width:0px;height:0px;\"></audio>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":52,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"related") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":4},"end":{"line":55,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"related") : stack1),{"name":"each","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":4},"end":{"line":91,"column":13}}})) != null ? stack1 : "")
    + "\n    <div class=\"u_btn_more_wrap\">\n        <a data-nclick=\"tot.more\" href=\""
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"moreDetailUrl") : stack1), depth0)) != null ? stack1 : "")
    + "\" class=\"u_btn_helpdict_more\" target=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"layerTarget") : depth0), depth0))
    + "\">"
    + ((stack1 = __default(require("../public/src/handlebars-helpers/gt.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"moreItem") : stack1),0,{"name":"gt","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":121},"end":{"line":94,"column":197}}})) != null ? stack1 : "")
    + alias3(__default(require("../public/src/handlebars-helpers/msg.js")).call(alias1,"allResults",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":94,"column":197},"end":{"line":94,"column":226}}}))
    + "<span class=\"u_helpdict_ico_more\"></span></a>\n    </div>\n\n    <div class=\"u_helpdict_notice\">\n        <span class=\"u_ico_notice\"></span>\n        <span class=\"u_notice_txt\">\n            <span class=\"u_notice_inner\">"
    + ((stack1 = __default(require("../public/src/handlebars-helpers/msg.js")).call(alias1,"caution",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":100,"column":41},"end":{"line":100,"column":69}}})) != null ? stack1 : "")
    + "</span>\n            <a data-nclick=\"tot.report\" href=\""
    + alias3(__default(require("../public/src/handlebars-helpers/reportLink.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"device") : depth0),{"name":"reportLink","hash":{},"data":data,"loc":{"start":{"line":101,"column":46},"end":{"line":101,"column":67}}}))
    + "\" class=\"u_report_link\" target=\"_blank\">"
    + alias3(__default(require("../public/src/handlebars-helpers/msg.js")).call(alias1,"reportButton",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":101,"column":107},"end":{"line":101,"column":138}}}))
    + "</a>\n        </span>\n    </div>\n    <button data-nclick=\"tot.close\" class=\"u_helpdict_close\">닫기</button>\n    <!--[D] 말풍선 화살표의 left값 (선택 단어의 중심)-->\n    <!--<span class=\"u_arrow\" style=\"left: 40px;\"></span>-->\n    <span id=\"tooltipLayer_pointer\" class=\"u_arrow\" style=\"left: 260px; display: block;\"></span>\n\n</div>\n";
},"useData":true,"useDepths":true});