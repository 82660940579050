var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../public/src/handlebars-helpers/gt.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"multiTab") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),1,{"name":"gt","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":14,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"u_helpdict_tab_area\">\n		        <div class=\"u_tab_wrap\" role=\"tablist\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"multiTab") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":5},"end":{"line":11,"column":14}}})) != null ? stack1 : "")
    + "		        </div>\n		    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			            <div class=\"u_btn_area\">\n			                <a data-nclick=\"tot."
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"source") : depth0), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"target") : depth0), depth0))
    + "dict\" href=\"javascript:;\" class=\"u_btn_tab my_multi_tooltip_btn\" data-lang=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"source") : depth0), depth0))
    + "\" targetLang=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"target") : depth0), depth0))
    + "\" shortName=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"shortName") : depth0), depth0))
    + "\" fullName=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fullName") : depth0), depth0))
    + "\" role=\"tab\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showName") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":9,"column":232},"end":{"line":9,"column":288}}})) != null ? stack1 : "")
    + "</a>\n			            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"showName") : depth0), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"shortName") : depth0), depth0));
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		        <dl class=\"u_helpdict_area\">\n		            <dt class=\"u_headword\">\n		                <a data-nclick=\"tot.entry"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isOtherDict") : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":43},"end":{"line":20,"column":73}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isNaverDictAppAccentia") : depths[1]),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":20,"column":75},"end":{"line":20,"column":246}}})) != null ? stack1 : "")
    + " class=\"u_btn_headword a\" target=\""
    + container.escapeExpression(alias2((depths[1] != null ? lookupProperty(depths[1],"layerTarget") : depths[1]), depth0))
    + "\">\n		                    "
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"entryName") : depth0), depth0)) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"supercript") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":22},"end":{"line":24,"column":29}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subEntry") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":22},"end":{"line":27,"column":29}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"chinaWord") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":22},"end":{"line":30,"column":29}}})) != null ? stack1 : "")
    + "		                </a>\n		            </dt>\n		            <dd class=\"u_pronunciation\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pronounceList") : depth0),{"name":"each","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":18},"end":{"line":38,"column":27}}})) != null ? stack1 : "")
    + "		            </dd>\n		            <dd class=\"u_definition\">\n		                <ul class=\"u_mean_word\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"partOfSpeechList") : depth0),{"name":"each","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":22},"end":{"line":56,"column":31}}})) != null ? stack1 : "")
    + "		                </ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"source") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":18},"end":{"line":60,"column":25}}})) != null ? stack1 : "")
    + "		            </dd>\n		        </dl>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "2";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "onclick=\"naverDictAppAccentiaNativeApi.loadingPageInMainDictModule('"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"destinationLink") : depth0), depth0))
    + "', true)\" ";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"destinationLink") : depth0), depth0))
    + "\"";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		                        <sup class=\"u_sup_item\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"supercript") : depth0), depth0))
    + "</sup>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		                        <span class=\"u_reference_word\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"subEntry") : depth0), depth0))
    + "</span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		                        <span class=\"u_origin_word\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"chinaWord") : depth0), depth0))
    + "</span>\n";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		                    <span class=\"u_pronunciation_item\">\n		                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"partOfSpeech") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":26},"end":{"line":36,"column":105}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sign") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":105},"end":{"line":36,"column":177}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pronunceUrl") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":177},"end":{"line":36,"column":360}}})) != null ? stack1 : "")
    + "\n		                    </span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"u_by_country\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"partOfSpeech") : depth0), depth0)) != null ? stack1 : "")
    + "</span>";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"u_phonetic_alphabet\">["
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"sign") : depth0), depth0)) != null ? stack1 : "")
    + "]</span>";
},"26":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button data-nclick=\"tot.tts"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"isOtherDict") : depths[2]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":224},"end":{"line":36,"column":257}}})) != null ? stack1 : "")
    + "\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tts") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.program(29, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":36,"column":266},"end":{"line":36,"column":313}}})) != null ? stack1 : "")
    + "\" data-url=\""
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"pronunceUrl") : depth0), depth0)) != null ? stack1 : "")
    + "\"></button>";
},"27":function(container,depth0,helpers,partials,data) {
    return "u_btn_tts";
},"29":function(container,depth0,helpers,partials,data) {
    return "u_btn_pronun";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../public/src/handlebars-helpers/gt.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"meaningList") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),1,{"name":"gt","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(35, data, 0),"data":data,"loc":{"start":{"line":43,"column":26},"end":{"line":55,"column":33}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		                            <li class=\"u_mean_word_item u_list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"meaningList") : depth0),{"name":"each","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":34},"end":{"line":47,"column":43}}})) != null ? stack1 : "")
    + "		                            </li>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		                                    <span class=\"u_word_mean\"><span class=\"u_words_num\">"
    + container.escapeExpression(__default(require("../../public/src/handlebars-helpers/add.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"index")),1,{"name":"add","hash":{},"data":data,"loc":{"start":{"line":46,"column":90},"end":{"line":46,"column":106}}}))
    + ".&nbsp;</span>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"desciption") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		                            <li class=\"u_mean_word_item\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"meaningList") : depth0),{"name":"each","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":34},"end":{"line":53,"column":43}}})) != null ? stack1 : "")
    + "		                            </li>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		                                    <span class=\"u_word_mean\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"desciption") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n";
},"38":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		                    <span class=\"u_origin_source\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isLine") : depths[1]),{"name":"if","hash":{},"fn":container.program(39, data, 0, blockParams, depths),"inverse":container.program(41, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":59,"column":52},"end":{"line":59,"column":148}}})) != null ? stack1 : "")
    + ": "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"source") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isOtherDict") : depths[1]),{"name":"if","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":160},"end":{"line":59,"column":223}}})) != null ? stack1 : "")
    + "</span>\n";
},"39":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../public/src/handlebars-helpers/msg.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"sourceLine",(depths[1] != null ? lookupProperty(depths[1],"userCode") : depths[1]),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":59,"column":69},"end":{"line":59,"column":103}}})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../public/src/handlebars-helpers/msg.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"source",(depths[1] != null ? lookupProperty(depths[1],"userCode") : depths[1]),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":59,"column":111},"end":{"line":59,"column":141}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    return " <i class=\"u_icon_blank_page\"></i>";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "onclick=\"naverDictAppAccentiaNativeApi.loadingPageInMainDictModule('"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"moreDetailUrl") : stack1), depth0))
    + "', true)\" ";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "href=\""
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"moreDetailUrl") : stack1), depth0)) != null ? stack1 : "")
    + "\"";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../public/src/handlebars-helpers/otherMeanings.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"moreItem") : stack1),{"name":"otherMeanings","hash":{},"data":data,"loc":{"start":{"line":65,"column":321},"end":{"line":65,"column":365}}})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../public/src/handlebars-helpers/msg.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"cautionLine",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":69,"column":56},"end":{"line":69,"column":88}}})) != null ? stack1 : "");
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../public/src/handlebars-helpers/msg.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"caution",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":69,"column":96},"end":{"line":69,"column":124}}})) != null ? stack1 : "");
},"55":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../public/src/handlebars-helpers/reportLink.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"device") : depth0),"line",{"name":"reportLink","hash":{},"data":data,"loc":{"start":{"line":70,"column":61},"end":{"line":70,"column":89}}}));
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isGlobalEnen") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(60, data, 0),"data":data,"loc":{"start":{"line":70,"column":89},"end":{"line":70,"column":222}}})) != null ? stack1 : "");
},"58":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../public/src/handlebars-helpers/reportLink.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"device") : depth0),"globalEnen",{"name":"reportLink","hash":{},"data":data,"loc":{"start":{"line":70,"column":113},"end":{"line":70,"column":147}}}));
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isNews") : depth0),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.program(63, data, 0),"data":data,"loc":{"start":{"line":70,"column":147},"end":{"line":70,"column":222}}})) != null ? stack1 : "");
},"61":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../public/src/handlebars-helpers/reportLink.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"device") : depth0),"news",{"name":"reportLink","hash":{},"data":data,"loc":{"start":{"line":70,"column":165},"end":{"line":70,"column":193}}}));
},"63":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../public/src/handlebars-helpers/reportLink.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"device") : depth0),{"name":"reportLink","hash":{},"data":data,"loc":{"start":{"line":70,"column":201},"end":{"line":70,"column":222}}}));
},"65":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../public/src/handlebars-helpers/msg.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"reportButtonLine",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":70,"column":283},"end":{"line":70,"column":318}}}));
},"67":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../public/src/handlebars-helpers/msg.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"reportButton",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":70,"column":326},"end":{"line":70,"column":357}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"tooltipLayer_dict\" class=\"u_helpdict_module green_dot u_type_f\" style=\"position:fixed\">\n	<audio  id='tip_audio' style=\"width:0px;height:0px;\"></audio>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiTab") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":15,"column":8}}})) != null ? stack1 : "")
    + "	    <div class=\"u_helpdict_wrap\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":63,"column":15}}})) != null ? stack1 : "")
    + "	    <div class=\"u_btn_more_wrap\">\n	        <a data-nclick=\"tot.more"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOtherDict") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":33},"end":{"line":65,"column":60}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNaverDictAppAccentia") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.program(47, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":65,"column":62},"end":{"line":65,"column":242}}})) != null ? stack1 : "")
    + " class=\"u_btn_helpdict_more\" target=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"layerTarget") : depth0), depth0))
    + "\">"
    + ((stack1 = __default(require("../../public/src/handlebars-helpers/gt.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"moreItem") : stack1),0,{"name":"gt","hash":{},"fn":container.program(49, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":296},"end":{"line":65,"column":372}}})) != null ? stack1 : "")
    + alias2(__default(require("../../public/src/handlebars-helpers/msg.js")).call(alias1,"allResults",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":65,"column":372},"end":{"line":65,"column":401}}}))
    + "<span class=\"u_helpdict_ico_more\"></span></a>\n	    </div>\n	    <div class=\"u_helpdict_notice\">\n	        <span class=\"u_notice_txt\">\n	            <span class=\"u_notice_inner\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLine") : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0, blockParams, depths),"inverse":container.program(53, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":69,"column":42},"end":{"line":69,"column":131}}})) != null ? stack1 : "")
    + "</span>\n	            <a data-nclick=\"tot.report\" href=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLine") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.program(57, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":70,"column":47},"end":{"line":70,"column":229}}})) != null ? stack1 : "")
    + "\" class=\"u_report_link\" target=\"_blank\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLine") : depth0),{"name":"if","hash":{},"fn":container.program(65, data, 0, blockParams, depths),"inverse":container.program(67, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":70,"column":269},"end":{"line":70,"column":364}}})) != null ? stack1 : "")
    + "</a>\n	        </span>\n	    </div>\n	    <button data-nclick=\"tot.close\" class=\"u_helpdict_close\"></button>\n	</div>\n</div>\n";
},"useData":true,"useDepths":true});