import $ from 'jQuery';
import classMap from '../config/codeClassMap.js';
import {
	getCurrentOptions
} from '../config/appConfig.js';

var playingBtn = null;
var existingSoundPlayer = null;
var audio = null;
var audioTimeOut = null;

export function setSoundPlayer() {

	var $playBtnList = $(classMap['playBtnClass']);
	if (!getCurrentOptions()['isPlayAudio'] && $playBtnList) {
		$playBtnList.remove();
		return;
	}
	existingSoundPlayer = window.soundPlayer || false;
	audio = $('#' + classMap['audioTagId']).get(0);

	if (audio) {

		$playBtnList.on('click', function(e) {
			//console.log(`[audio-player.js] click`);
			var option_ = getCurrentOptions();
			if (option_.callbackFunc && typeof(option_.callbackFunc) == 'function') {
				option_.callbackFunc(e); //点击发音按钮时，调用方需要处理的业务（如： https://oss.navercorp.com/skywalker/dict_platform/issues/4306）
			}
			if (playingBtn) {
				_stop(e);
			}
			_play(this, e);
			var self = this;
			audioTimeOut = setTimeout(function() {
				audioTimeOut = null;
				if (playingBtn) {
					$(self).removeClass("u_active");
					playingBtn = null;
					// call callback
					let afterPlayCB = getCurrentOptions().eventAfterPlaySound;
					if (afterPlayCB) {
						afterPlayCB(e);
					}
				}

			}, 4000);
			//e.stopPropagation();
		});
		$(audio).on('ended', function(e) {
			_stop(e);
		});
	}


}

function _play(btnEle, e) {
	playingBtn = btnEle;
	if (existingSoundPlayer && existingSoundPlayer.nowPlaying >= 0) {
		existingSoundPlayer.stop(existingSoundPlayer.nowPlaying);
	}
	var beforePlayCallBack = getCurrentOptions().eventBeforePlaySound;
	if (beforePlayCallBack) {
		beforePlayCallBack(e);
	}
	let destination = $(playingBtn).attr('data-url');
	if (destination.indexOf('https://tip.dict.naver.com/nvoice/tts') !== -1) {
		destination = destination.replace('https://tip.dict.naver.com/nvoice/tts', 'https://dict.naver.com/api/nvoice').replace('service=tip', 'service=dictionary');
	}
	//audio.src = $(playingBtn).attr('data-url');
	audio.src = destination;
	audio.load();
	audio.play();
	$(playingBtn).addClass("u_active"); //btn image change

	return false;
}


function _stop(e) {

	if (!playingBtn) {
		return;
	}
	if (audioTimeOut) {

		clearTimeout(audioTimeOut);
		audioTimeOut = null;
	}
	$(playingBtn).removeClass("u_active");

	if (audio && audio.pause) {
		audio.pause();
	}
	if (audio && !isNaN(audio.duration)) {
		audio.currentTime = 0;
	}
	playingBtn = null;
	let afterPlayCB = getCurrentOptions().eventAfterPlaySound;
	if (afterPlayCB) {
		afterPlayCB(e);
	}
}
