var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "u_active";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"u_word_zoom\" data-lang='hj'>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"tooltipLayer_hanja_zoom\" class=\"u_helpdict_zoom_area u_hanja green_dot\" lang=\"zh\" style=\"opacity: 1; max-width:360px\">\n    <div id=\"tooltipLayer_hanja_zoom_list\" class=\"u_helpdict_zoom_frame\">\n        <button id=\"hanja_zoom_prev_blank\" class=\"u_word_blank "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"zoomHasPrev") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":63},"end":{"line":3,"column":97}}})) != null ? stack1 : "")
    + "\"></button>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"hanjaArray") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":17}}})) != null ? stack1 : "")
    + "        <button id=\"hanja_zoom_next_blank\" class=\"u_word_blank "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"zoomHasNext") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":63},"end":{"line":7,"column":97}}})) != null ? stack1 : "")
    + "\"></button>\n    </div>\n    <div id='hanja_zoom_prev_button' class=\"u_btn_wrap u_prev "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"zoomHasPrev") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":62},"end":{"line":9,"column":96}}})) != null ? stack1 : "")
    + "\">\n        <button class=\"u_btn_prev\" type=\"button\" aria-label=\"이전 한자 보기\"></button>\n    </div>\n    <div id='hanja_zoom_next_button' class=\"u_btn_wrap u_next "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"zoomHasNext") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":62},"end":{"line":12,"column":96}}})) != null ? stack1 : "")
    + "\">\n        <button class=\"u_btn_next\" type=\"button\" aria-label=\"다음 한자 보기\"></button>\n    </div>\n</div>";
},"useData":true});