import $ from 'jQuery';

import Converter from './converter.js';
import eventAttach from './eventAttacher';
import supportPair from './config/pairBand.json';
import userCodeBand from './config/userCodeBand.json';
import {initConfig, getCurrentOptions, mergeOptions, setCallbackFunction, setIgnoreElementList, getLangList, getContentSelector, setContentSelector, setServiceURL, setServiceDomain, setNNBCookie} from './config/appConfig.js';
import {getRawRefinerList} from './config/importByLang.js';


let versionTag = 157;
let converter = null;
let isInitFinished = false;

function _verifyCondition(contentSelector) {
    //console.log(`[index.js] _verifyCondition`);
    if (!isInitFinished) {
        //console.log(`can not be processed until init finished`);
    }
    if (!contentSelector) {
        //console.log(`invalid contentSelector, please check parameter`);
    }
}

function _callConverter (contentSelector) {
    //console.log(`[index.js] _callConverter`);
    if (getCurrentOptions().legacy && getCurrentOptions().legacy.parseWhole) {
        converter.convert_legacy(contentSelector);
    } else {
        converter.convertGeneralLanguage(contentSelector);
    }
}

export function addOtherArea(contentSelector,option,callback) {
    //console.log(`[index.js] newtooltip, addOtherArea, isInitFinished = ${isInitFinished}`);
    _verifyCondition(contentSelector);
    mergeOptions(option);
    if(typeof callback === 'function') setCallbackFunction(callback);
    setContentSelector(getContentSelector() +', ' + contentSelector);
    setIgnoreElementList();
    //let timeTaken1 = "time taken by newtooltip addOtherArea";
    //console.time(timeTaken1);
    //let timeTaken2 = "time taken by convert";
    //console.time(timeTaken2);
    _callConverter(contentSelector);

    //console.timeEnd(timeTaken2);
    //let timeTaken3 = "time taken by eventAttach";
    let $targetArray = $(contentSelector);
    $targetArray = $targetArray.filter(function (index) {
        let results = $(this).is(getContentSelector());//getCurrentOptions().contentSelector
        if (results) {
            return false;
        }
        results = $(this).parents(getContentSelector());
        if (results.length == 0) {
            return true;
        } else {
            return false;
        }
    });
    if ($targetArray.length > 0) {
        eventAttach($targetArray);
    }

    //console.timeEnd(timeTaken3);
    //console.timeEnd(timeTaken1);
    return true;
}

export function reloadArea(contentSelector,option,callback) {
    //console.log(`[index.js] newtooltip, reloadArea, isInitFinished = ${isInitFinished}`);
    _verifyCondition(contentSelector);
    mergeOptions(option);
    if(typeof callback === 'function') setCallbackFunction(callback);
    setIgnoreElementList();
    _callConverter(contentSelector);
    eventAttach($(contentSelector));
    return true;
}

export function initGreendot(contentSelector, option, callbackFunc) {
    //console.log(`[index.js] newtooltip, initGreendot, contentSelector = ${contentSelector}`);
    option.layerType = "greendot";
    option.highlightColor = "blue";
    init(contentSelector, option, callbackFunc);
    return true;
}

function _setLaimlogOption() {
    //console.log(`[index.js] _setLaimlogOption(), document.cookie = ${document.cookie}, window.location.href = ${window.location.href}, window.location.hostname = ${window.location.hostname}`);
    let cookiesMap = document.cookie.split(";").map( value => {
        let val =value.split("=");
        return { "key" : val[0], "value" : val[1] };
    });

    for( let i = 0 ; i < cookiesMap.length ; i++ ){
        if (cookiesMap[i].key === 'NNB') {
            //console.log(`cookiesMap[i].key =${cookiesMap[i].key}, cookiesMap[i].value = ${cookiesMap[i].value}`);
            setNNBCookie(cookiesMap[i].value);
        }
    }

    setServiceURL(window.location.href);
    setServiceDomain(window.location.hostname);
}

export function init(contentSelector, option, callbackFunc) {
    //console.log(`[index.js] newtooltip, init`);
    if (!contentSelector) {
        //console.log(`[index.js] int, invalid contentSelector, please check parameter`);
    }

    //let timeTakenTotally = "time taken by newtooltip init totally";
    //console.time(timeTakenTotally);
    //let timeTakenConvert = "time taken by convert";
    //console.time(timeTakenConvert);
    _setLaimlogOption();
    initConfig(contentSelector, option, callbackFunc); //set up options

    let langList = getLangList();
    let rawRefiners = getRawRefinerList(langList);
    converter = new Converter();
    converter.init(rawRefiners);
    _callConverter(contentSelector);
    //console.timeEnd(timeTakenConvert);
    //let timeTakenEventAttach = "time taken by eventAttach";
    //console.time(timeTakenEventAttach);
    eventAttach($(contentSelector));
    //console.timeEnd(timeTakenEventAttach);
    isInitFinished = true;
    //console.timeEnd(timeTakenTotally);
    return true;
}

export function printPairs() {
    console.log('tip version tag :' + versionTag);
    console.dir(supportPair);
    return true;
}

export function printUserCode() {
    console.log('tip version tag :' + versionTag);
    console.dir(userCodeBand);
    return true;
}