
const template = function(item){

    return `<span class="u_normal_word">${item}</span>`;
}
// bold only words in [items].entryName which are included original query
export default function weaken(sourceLang,sQuery,body){

    //영어일때만 해당
    if(sourceLang !== 'en'){
        return
    }
    // body.result.[items].entryName
    sQuery = sQuery.toLowerCase();
    body.result.items.forEach(element => {

        var tarList = element.entryName.split(' ');
        var weakendList = tarList.map(item =>{
                item = item.trim();
                var lcItem = item.toLowerCase();

                if(sQuery.indexOf(lcItem)< 0 ){
                    return template(item);
                }
                return item;
        });
        element.entryName = weakendList.join(' ');
    });
    

}