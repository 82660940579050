import $ from 'jQuery';
import {
	getCurrentNclickDomain,
	getCurrentNsc
} from '../config/appConfig.js';

const protocal = (window.location.protocol == "https:") ? "https:" : "http:";
let nclk_ct = 0;

function _nclk_l(o, tU) {
	let nClickDomain = getCurrentNclickDomain();
	var l, tN, tH;
	if (o && o.href) {
		tN = o.tagName.toLowerCase();
		tH = o.href.toLowerCase();
		if (tH && tH.indexOf(protocal + "//" + nClickDomain) == 0) { // href is cc.naver.com ...
			l = o.href;
		} else if (tH && tH.indexOf(protocal + "//" + nClickDomain) != 0 && tN && tN != "img") {
			l = tU + "&u=" + encodeURIComponent(o.href); // append target url
		}
		return l;
	}
	return tU + "&u=about%3Ablank"; // no tareget url - default
}

function _send(clickId, event) {
	let nClickDomain = getCurrentNclickDomain();
	var nsc = getCurrentNsc();
	if (!nsc) {
		return;
	}
	var target = event && event.target;
	var l = protocal + "//" + nClickDomain + "/cc?a=" + clickId + "&nsc=" + nsc + "&m=0";

	l = _nclk_l(target, l);
	if (nclk_ct > 0) {
		var t = new Date().getTime();
		l += "&nt=" + t; // Aviod image cache
	}


	var o = new Image();
	o.src = l;
	o.onload = function(event) {
		// console.log(event);
		o.onload = null;
		return;
	}
	o.onerror = function() {

		// console.log('error!!!');
		o.onerror = null;
		return;
	}

	nclk_ct++;

}
export function tipNclick(event) {
	var clickId = $(event.target).attr('data-nclick');
	//console.log(`[tip-nclick.js] tipNclick, clickId = ${clickId}`);
	_send(clickId, event);
}

export function sendOpenNclick() {
	_send('tot.open', null);
}

export function sendCloseNclick() {
	_send('tot.close', null);
}

export function sendCloseNclick1() {
	_send('tot.close1', null);
}
