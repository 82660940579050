import { getCurrentOptions } from './appConfig.js';

const highlightClass = {
	"blue":"u_green_dot_active"
};

const codeClassMap = {
	
	"mousePointerClass":"u_news_active",
	"audioTagId":"tip_audio",
	"playBtnClass":".u_btn_pronun, .u_btn_tts",
	"_sHighLightingClass": "u_active",
	"_sHighLightingClass_selector":".u_active",
	"sTargetClass":"span.u_word_dic",
	"tipHook":"[data-hook='tip']",
	"rawHook":"[data-type='ore']",
	"tipLayerId":"tooltipLayer_dict",
	"sPointerId":"tooltipLayer_pointer",
	"uparrowClass":"u_up_arrow",
	"downarrowClass":"u_dn_arrow",
	"zoomLayerId":"tooltipLayer_hanja_zoom",
	"zoomHanja":"button.u_word_zoom",
	get "sHighLightingClass_selector"(){
		var selector = this["_sHighLightingClass_selector"];
		var currentOptions = getCurrentOptions();
		var highLightClassNm = highlightClass[currentOptions['highlightColor']];
		if(highLightClassNm){
			selector = '.'+highLightClassNm;
		}
		if(currentOptions['mousePointer']){
			// className = className + " "+this['mousePointerClass'];
			selector = '.'+this['mousePointerClass'];
		}
        //console.log(`[codeClassMap.js] get sHighLightingClass_selector, selector = ${selector}`);
		return selector;
	},
	get "sHighLightingClass"() {
		var className = this["_sHighLightingClass"];
		var currentOptions = getCurrentOptions();
		var highLightClassNm = highlightClass[currentOptions['highlightColor']];
		if(highLightClassNm){
			className = highLightClassNm;
		}

		// news PC need mouse pointer Icon
		if(currentOptions['mousePointer']){
			// className = className + " "+this['mousePointerClass'];
			className = this['mousePointerClass'];
		}
		//console.log(`[codeClassMap.js] get sHighLightingClass className= ${className}`);
		return className;
	}
};

export default codeClassMap; 