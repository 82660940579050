const data = {};

const tooltipLayerHtmlCache = {

    setData(key, value){
        //console.log(`[tooltip-layer-html-cache.js] setData, key = ${key}`);
        data[key] = value;
    },
    hasData(key){

        return !!data[key];
    },

    getData(key){

        return data[key];
    }
};

export default tooltipLayerHtmlCache;