var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <dl class=\"u_helpdict_area\">\n        <dt class=\"u_headword\">\n          <a data-nclick=\"tot.entry\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"destinationLink") : depth0), depth0))
    + "\" class=\"u_btn_headword\" target=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"layerTarget") : depths[1]), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"result") : depths[1])) != null ? lookupProperty(stack1,"query") : stack1), depth0))
    + "\n          <span class=\"u_origin_word\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"read") : depth0), depth0))
    + "</span>\n          </a>\n        </dt>\n        <dd class=\"u_definition\">\n            <ul class=\"u_mean_word\">\n                <li class=\"u_mean_word_item u_list\">\n                    <span class=\"u_hanja_option\">\n                        <span class=\"u_option_item\">부수</span>\n                        <span class=\"u_option_radical\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"busuHanja") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n                        <span class=\"u_option_item\">총획</span>\n                        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"totalStrokeCount") : depth0), depth0))
    + "획\n                    </span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"meaningList") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":20,"column":29}}})) != null ? stack1 : "")
    + "                </li>\n            </ul>\n        </dd>\n    </dl>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"u_word_mean\"><span class=\"u_words_num\">"
    + container.escapeExpression(__default(require("../../public/src/handlebars-helpers/add.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"index")),1,{"name":"add","hash":{},"data":data,"loc":{"start":{"line":19,"column":76},"end":{"line":19,"column":92}}}))
    + ".&nbsp;</span>"
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../public/src/handlebars-helpers/otherMeanings.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"moreItem") : stack1),{"name":"otherMeanings","hash":{},"data":data,"loc":{"start":{"line":27,"column":146},"end":{"line":27,"column":190}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"tooltipLayer_dict\" class=\"u_helpdict_module green_dot u_type_hanja\" style=\"display: block; left: 15px; right: 15px; top: 55px;\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":25,"column":12}}})) != null ? stack1 : "")
    + "    <div class=\"u_btn_more_wrap\">\n        <a data-nclick=\"tot.more\" href=\""
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"moreDetailUrl") : stack1), depth0)) != null ? stack1 : "")
    + "\" class=\"u_btn_helpdict_more\" target=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"layerTarget") : depth0), depth0))
    + "\">"
    + ((stack1 = __default(require("../../public/src/handlebars-helpers/gt.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"moreItem") : stack1),0,{"name":"gt","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":121},"end":{"line":27,"column":197}}})) != null ? stack1 : "")
    + alias3(__default(require("../../public/src/handlebars-helpers/msg.js")).call(alias1,"allResults",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":27,"column":197},"end":{"line":27,"column":226}}}))
    + "<span class=\"u_helpdict_ico_more\"></span></a>\n    </div>\n    <div class=\"u_helpdict_notice\" style=\"display:none\">\n        <span class=\"u_ico_notice\"></span>\n        <span class=\"u_notice_txt\">\n            <span class=\"u_notice_inner\">"
    + ((stack1 = __default(require("../../public/src/handlebars-helpers/msg.js")).call(alias1,"caution",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":32,"column":41},"end":{"line":32,"column":69}}})) != null ? stack1 : "")
    + "</span>\n            <a data-nclick=\"tot.report\" href=\""
    + alias3(__default(require("../../public/src/handlebars-helpers/reportLink.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"device") : depth0),{"name":"reportLink","hash":{},"data":data,"loc":{"start":{"line":33,"column":46},"end":{"line":33,"column":67}}}))
    + "\" class=\"u_report_link\" target=\"_blank\">"
    + alias3(__default(require("../../public/src/handlebars-helpers/msg.js")).call(alias1,"reportButton",(depth0 != null ? lookupProperty(depth0,"userCode") : depth0),{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":33,"column":107},"end":{"line":33,"column":138}}}))
    + "</a>\n        </span>\n    </div>\n    <button data-nclick=\"tot.close\" class=\"u_helpdict_close\"></button>\n</div>";
},"useData":true,"useDepths":true});