import {getCurrentOptions} from '../config/appConfig.js';
import {singleHanja_marking} from './raw-refine-hj.js'
import $ from 'jQuery';

//"・"은 katakana middle dot 이라고도 하는데 unicode는 U+30FB 이고 일본어로 인식된다.
const sPatternHiragana = "\u3040-\u309F";	// 히라가나
//let sPatternKatakana = "\u30A0-\u30FF";	// 오리지날 가타카나
const sPatternKatakana = "\u30A0-\u30FA\u30FC-\u30FF";	// 가타카나 특수문자 "・"은 katakana middle dot 이라고도 하는데 unicode는 U+30FB 이고 일본어로 인식된다. 하기에 u30FB 제외
const sPatternKatakanaExtension = "\u31F0-\u31FF";	// 가타카나 음성 확장
const sPatternCJKUnifiedIdeographsExtensionA = "\u3400-\u4DBF";	// 한자확장
const sPatternCJKUnifiedIdeographs = "\u4E00-\u9FBF";	// 한중일 통합 한자
const sPatternCJKCompatibilityIdeographs = "\uF900-\uFAFF";	// 한중일 호환용 한자
//const sKoreanPattern = "ㄱ-ㅣ|가-힣";
//const sKoreanPattern = "\uac00-\ud7af"; // 가-힣 과 동일함
const sPatternEnglishAlphabet = "a-zA-Z" ; // 가장 기본적인 영문자
const sFullwidthLatinSmall = "\uFF41-\uFF5A"; // a-z
const sFullwidthLatinCapital = "\uFF21-\uFF3A"; // A-Z
const sEnglishPattern = sPatternEnglishAlphabet + sFullwidthLatinCapital + sFullwidthLatinSmall;

//글자단위
let defaultCharacterPattern = "[" + sEnglishPattern + sPatternHiragana + sPatternKatakana + sPatternKatakanaExtension + sPatternCJKUnifiedIdeographsExtensionA + sPatternCJKUnifiedIdeographs + sPatternCJKCompatibilityIdeographs + "]";
//문장구절단위
let defaultSentencePattern = defaultCharacterPattern + "+";

//let normalPunctuationPattern = /[~`!@#$%^&*(){}\[\]\|\\,.<>?"'();:_+-=/]/g;
//let chinesePunctuationPattern = /[\u3002\uff1b\uff0c\uff1a\u201c\u201d\uff08\uff09\u3001\uff1f\u300a\u300b]/g;

function templateMarking(sMatchedText) {
    //console.log(`[raw-refine-ja.js] templateMarking, sMatchedText = ${sMatchedText}`);
    return`<span data-type="ore" data-lang="ja">${sMatchedText}</span>`;
}

const markerByLang = {
    _patternCharacter: null,
    _patternSentence: null,
    _pattern_legacy: null,
    sourceLang: "ja",

    getCharacterPattern: function() {
        // let sQuantifiersPattern = "{1}";		// 1글자만 고정 탐색
        this._patternCharacter = new RegExp(defaultCharacterPattern, "ig");

        return this._patternCharacter;
    },
    getSentencePattern: function () {
        let minWordLength = getCurrentOptions().lang["ja"].minWordLength;
        if (minWordLength === undefined) minWordLength = 1;
        let sQuantifiersPattern = "{" + minWordLength + ",}";
        let sPattern = defaultCharacterPattern + sQuantifiersPattern;
        this._patternSentence = new RegExp(sPattern, "ig");

        return this._patternSentence;
    },
    getPattern_legacy: function () {
        let sPattern = "(<a [^>]+>([\\s\\S]*?)<\/a>|<span class=\"u_word_dic[^>]+>([\\s\\S]*?)<\/span>|<script[^>]*>([\\s\\S]*?)<\/script>|<style[^>]*>([\\s\\S]*?)<\/style>|<[^>]+>|" + defaultSentencePattern + ")";
        this._pattern_legacy = new RegExp(sPattern, "ig");
        
        return this._pattern_legacy;
    },

    mark: function (originalTextContent) {
        //console.log(`[raw-refine-ja.js] mark, original text = ${originalTextContent}`);
        let regExp = this._patternCharacter || this.getCharacterPattern();
        let isMatched = false;
        let reformedTextContent = originalTextContent.replace(regExp, function (match,$1) {
            let sMatchedText = match;
            //console.log(`[raw-refine-js.js] markPureText, each sMatchedText = ${sMatchedText}, originalText = ${originalTextContent}`);
            isMatched = true;
            return templateMarking(sMatchedText);
        });
        //console.log(`[raw-refine-ja.js] markPureText, reformed text = ${reformedTextContent}`);
        return {
            textContent: reformedTextContent,
            isMatched: isMatched
        };
    },
    engrave: function (rubies, event, $eleList, clickedIdx) {
        //console.log(`[raw-refine.ja.js] engrave`);
        let taggedObj = rubies.pop();

        if (!taggedObj || !taggedObj.raw || typeof (taggedObj.raw) !== 'string') {
            return;
        }

        let targetIdx = -1;
        rubies.forEach((obj, index) => {
            if (obj.indices.indexOf(clickedIdx) >= 0) {
                targetIdx = index;
            }
        });

        let sourceLang = event.target.getAttribute('data-lang');
        let uniqueId = Math.ceil(event.timeStamp);
        let newTagStrList = new Array($eleList.length);
        let i = 0;
        rubies.forEach(obj => {

            let sqry = obj.raw;
            i++;
            obj.indices.forEach(function (index) {
                newTagStrList[index] = `<span class="u_word_dic" data-hook="tip" data-type="arken" data-lang="ja" data-grp='${uniqueId+i}' data-sqry="${sqry}">${$($eleList.get(index)).text()}</span>`;
            });
        });

        let targetElementNew = null;
        //console.log(`[ray-refine-en.js] engrave, $eleList[0] is`);
        //console.log($eleList[0]);
        //console.log(`[ray-refine-en.js] engrave, $eleList[0].parentNode is`);
        //console.log($eleList[0].parentNode);
        $eleList.each(function(index,value){
            let parentNode = value.parentNode;
            let html = null;
            if(newTagStrList[index]){
                html = $.parseHTML(newTagStrList[index]);
                //$(this).before(html);
                //$(this).remove();
            }else{
                let str= `<span class="u_word_dic" data-hook="tip" data-type="word" data-lang="ja">${$($eleList.get(index)).text()}</span>`;
                html = $.parseHTML(str);
                //$(this).before(html);
                //$(this).remove();
            }
            let newNode;
            try {
                /*for (let j=0;j<html.length;j++) {
                    let newNode = document.importNode(html[j],true);
                    parentNode.insertBefore(newNode,value);
                }*/
                newNode = document.importNode(html[0],true);
                parentNode.insertBefore(newNode,value);
                parentNode.removeChild(value);
            } catch (err) {
                console.log(err.message + "\n툴팁 대상에 오류가 있습니다. 아래 툴팁 대상이 올바른지 확인해보시기 바랍니다. \nerror engrave en target:" + value);
            }

            /*if(isMobile && (index == clickedIdx)){ // if it was originated from mobile touch
                // $(html).trigger("click");
                targetElementNew = newNode;
            }*/
            if (index === clickedIdx) {
                targetElementNew = newNode;
            }

        });


        if (targetIdx > -1 && getCurrentOptions().isMobile) {
            //console.log(`[raw-refine.ja.js] engraveNonRuby, trigger mobile click`);
            $(targetElementNew).trigger('click');
        } else if (targetIdx > -1 && getCurrentOptions().isMobile === false) {
            //$(targetElementNew).trigger('mouseover');
        }

        return targetElementNew;
    },
};


export default markerByLang;