import {
	getCurrentOptions
} from '../config/appConfig.js';
import $ from 'jQuery';

//legacy
const sExtendPattern = "ÁÉÍÓÚÜáéíóúüÀ-ÿ";
const defaultPattern = "[a-z’'\\-\\.\\?!,;" + sExtendPattern + "]";
const patternForHtmlEscape = "[a-z" + sExtendPattern + "]";

//new
const englishSentencePattern = "[a-z0-9A-ZÁÉÍÓÚÜáéíóúüÀ-ÿ’'\\-\\.\\/·&~;]";
//특수문자 html entity 패턴 예: &nbsp;
const symbolEntityPattern = "&[a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6};";
//const symbolEntityRegex = /&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/ig;

function template_ore_marking(sMatchedText) {
	//console.log(`[raw-refine-en.js] template_ore_marking`);
	if ((getCurrentOptions().skipNumeral || getCurrentOptions().prCode == "news") && /^[0-9].*?[0-9]*/.test(sMatchedText)) {
		return sMatchedText;
	} else {
		return `<span data-type="ore" data-lang="en">${sMatchedText}</span>`;
	}
}

const markerByLang = {

	_pattern: null,
	_pattern_legacy: null,
	sourceLang: "en",
	encode: {
		BASIC_ESCAPE: ["&quot;", "&amp;", "&lt;", "&gt;", "&apos;"],
		ISO8859_1_ESCAPE: ["&nbsp;", "&iexcl;", "&cent;", "&pound;", "&curren;", "&yen;", "&brvbar;", "&sect;", "&uml;", "&copy;", "&ordf;", "&laquo;", "&not;", "&shy;", "&reg;", "&macr;", "&deg;", "&plusmn;", "&sup2;", "&sup3;", "&acute;", "&micro;", "&para;", "&middot;", "&cedil;", "&sup1;", "&ordm;", "&raquo;", "&frac14;", "&frac12;", "&frac34;", "&iquest;", "&Agrave;", "&Aacute;", "&Acirc;", "&Atilde;", "&Auml;", "&Aring;", "&AElig;", "&Ccedil;", "&Egrave;", "&Eacute;", "&Ecirc;", "&Euml;", "&Igrave;", "&Iacute;", "&Icirc;", "&Iuml;", "&ETH;", "&Ntilde;", "&Ograve;", "&Oacute;", "&Ocirc;", "&Otilde;", "&Ouml;", "&times;", "&Oslash;", "&Ugrave;", "&Uacute;", "&Ucirc;", "&Uuml;", "&Yacute;", "&THORN;", "&szlig;", "&agrave;", "&aacute;", "&acirc;", "&atilde;", "&auml;", "&aring;", "&aelig;", "&ccedil;", "&egrave;", "&eacute;", "&ecirc;", "&euml;", "&igrave;", "&iacute;", "&icirc;", "&iuml;", "&eth;", "&ntilde;", "&ograve;", "&oacute;", "&ocirc;", "&otilde;", "&ouml;", "&divide;", "&oslash;", "&ugrave;", "&uacute;", "&ucirc;", "&uuml;", "&yacute;", "&thorn;", "&yuml;"],
		HTML40_EXTENDED_ESCAPE: ["&fnof;", "&Alpha;", "&Beta;", "&Gamma;", "&Delta;", "&Epsilon;", "&Zeta;", "&Eta;", "&Theta;", "&Iota;", "&Kappa;", "&Lambda;", "&Mu;", "&Nu;", "&Xi;", "&Omicron;", "&Pi;", "&Rho;", "&Sigma;", "&Tau;", "&Upsilon;", "&Phi;", "&Chi;", "&Psi;", "&Omega;", "&alpha;", "&beta;", "&gamma;", "&delta;", "&epsilon;", "&zeta;", "&eta;", "&theta;", "&iota;", "&kappa;", "&lambda;", "&mu;", "&nu;", "&xi;", "&omicron;", "&pi;", "&rho;", "&sigmaf;", "&sigma;", "&tau;", "&upsilon;", "&phi;", "&chi;", "&psi;", "&omega;", "&thetasym;", "&upsih;", "&piv;", "&bull;", "&hellip;", "&prime;", "&Prime;", "&oline;", "&frasl;", "&weierp;", "&image;", "&real;", "&trade;", "&alefsym;", "&larr;", "&uarr;", "&rarr;", "&darr;", "&harr;", "&crarr;", "&lArr;", "&uArr;", "&rArr;", "&dArr;", "&hArr;", "&forall;", "&part;", "&exist;", "&empty;", "&nabla;", "&isin;", "&notin;", "&ni;", "&prod;", "&sum;", "&minus;", "&lowast;", "&radic;", "&prop;", "&infin;", "&ang;", "&and;", "&or;", "&cap;", "&cup;", "&int;", "&there4;", "&sim;", "&cong;", "&asymp;", "&ne;", "&equiv;", "&le;", "&ge;", "&sub;", "&sup;", "&sube;", "&supe;", "&oplus;", "&otimes;", "&perp;", "&sdot;", "&lceil;", "&rceil;", "&lfloor;", "&rfloor;", "&lang;", "&rang;", "&loz;", "&spades;", "&clubs;", "&hearts;", "&diams;", "&OElig;", "&oelig;", "&Scaron;", "&scaron;", "&Yuml;", "&circ;", "&tilde;", "&ensp;", "&emsp;", "&thinsp;", "&zwnj;", "&zwj;", "&lrm;", "&rlm;", "&ndash;", "&mdash;", "&lsquo;", "&rsquo;", "&sbquo;", "&ldquo;", "&rdquo;", "&bdquo;", "&dagger;", "&Dagger;", "&permil;", "&lsaquo;", "&rsaquo;", "&euro;"]
	},

	getPattern() {
		let minWordLength = getCurrentOptions().lang["en"].minWordLength;
		if (minWordLength === undefined) minWordLength = 1;
		let sQuantifiersPattern = "{" + minWordLength + ",}";
		let sPattern = `${symbolEntityPattern}|${englishSentencePattern}${sQuantifiersPattern}`;
		//console.log(`[raw-refine-en.js] sPattern = ${sPattern}`);
		this._pattern = new RegExp(sPattern, "ig");

		return this._pattern;
	},
	getPattern_legacy() {
		let minWordLength = getCurrentOptions().lang["en"].minWordLength;
		if (minWordLength === undefined) minWordLength = 1;
		let sQuantifiersPattern = "{" + minWordLength + ",}";
		let sPattern = "(<a [^>]+>([\\s\\S]*?)<\/a>|<span class=\"u_word_dic[^>]+>([\\s\\S]*?)<\/span>|<script[^>]*>([\\s\\S]*?)<\/script>|<style[^>]*>([\\s\\S]*?)<\/style>|<[^>]+>|&" + patternForHtmlEscape + sQuantifiersPattern + ";|" + defaultPattern + sQuantifiersPattern + ")";
		this._pattern_legacy = new RegExp(sPattern, "ig");

		return this._pattern_legacy;
	},
	mark(originalTextContent) {
		//console.log(`[raw-refine-en.js] mark, original textContent = ${originalTextContent}`);
		let regExp = this._pattern || this.getPattern();
		let encode = this.encode;
		let isMatched = false;

		let reformedTextContent = originalTextContent.replace(regExp, function(match, $1) {

			let sMatchedText = match;
			//console.log(`[rew-refine-en.js]mark, match = ${match}`);

			if (!sMatchedText.replace(/[\-\.\/’'·&~;]+/g, '')) {
				return sMatchedText;
			}

			if (sMatchedText.charAt(0) === '&' && sMatchedText.charAt(sMatchedText.length - 1) === ';') {
				//console.log(`[rew-refine-en.js]mark, html entity, match = ${match}`);
				if (encode.BASIC_ESCAPE.indexOf(sMatchedText) > -1 || encode.ISO8859_1_ESCAPE.indexOf(sMatchedText) > -1 || encode.HTML40_EXTENDED_ESCAPE.indexOf(sMatchedText) > -1) {
					return sMatchedText;
				}
			}

			isMatched = true;

			// she is 'good' girl 시 good 앞뒤의 문장부호 제외시켜야
			if (sMatchedText.charAt(0) == "'" && sMatchedText.charAt(sMatchedText.length - 1) == "'") {
				return "'" + template_ore_marking(sMatchedText.substring(1, sMatchedText.length - 1)) + "'";
			}

			//N/A 와 같은 케이스
			if (sMatchedText.indexOf('/') !== -1 && sMatchedText.length > 3) {
				let newString = '';
				let wordArray = sMatchedText.split('/');
				for (let i = 0; i < wordArray.length; i++) {
					if (i === 0) {
						newString = template_ore_marking(wordArray[0]);
					} else {
						newString = newString + "/" + template_ore_marking(wordArray[i]);
					}
				}
				return newString;
			}

			// 문장 맨 마지막에 따라는 ".", ";"과 같은 문장기호을 제외시켜야함
			// 정상적으로 인색해야하는 케이스: a.m., I.B.M, D.Journ, Newf., abbr.,
			if ((sMatchedText.match(/\./g) || []).length === 1 && sMatchedText.indexOf('.') === sMatchedText.length - 1) {
				return template_ore_marking(sMatchedText.substring(0, sMatchedText.length - 1)) + ".";
			}

			if ((sMatchedText.match(/;/g) || []).length === 1 && sMatchedText.indexOf(';') === sMatchedText.length - 1) {
				return template_ore_marking(sMatchedText.substring(0, sMatchedText.length - 1)) + ";";
			}

			return template_ore_marking(sMatchedText);
		});

		//console.log(`[raw-refine-en.js] mark, reformed text = ${reformedTextContent}`);
		return {
			textContent: reformedTextContent,
			isMatched: isMatched
		};
	},
	mark_legacy(sHtml) {
		let encode = this.encode;
		let oPattern = this._pattern_legacy || this.getPattern_legacy();

		sHtml = sHtml.replace(oPattern, function($1) {
			let sMatchedText = $1;
			if (!sMatchedText.replace(/[\-\.\?!,;]+/g, '')) {
				return sMatchedText;
			}

			if (sMatchedText.charAt(0) == '<' && sMatchedText.charAt(sMatchedText.length - 1) == '>') {
				return sMatchedText;
			} else if (sMatchedText.charAt(0) == '&' && sMatchedText.charAt(sMatchedText.length - 1) == ';') {
				if (encode.BASIC_ESCAPE.indexOf(sMatchedText) > -1 || encode.ISO8859_1_ESCAPE.indexOf(sMatchedText) > -1 || encode.HTML40_EXTENDED_ESCAPE.indexOf(sMatchedText) > -1) {
					return sMatchedText;
				}
			}

			return template_ore_marking(sMatchedText);
		});

		return sHtml;
	},
	engrave(rubies, event, $eleList, clickedIdx) {
		//console.log(`[raw-refine-en.js] engrave`);
		let isValidPhrase = function($eleList, indices) { //return true/false
			let start = indices.shift();
			let end = indices.pop();
			let pattern = /[\.\?!,;]+$/;
			for (let i = start; i <= end; i++) {
				let text = $($eleList.get(i)).text().trim();
				if (pattern.test(text) && i !== end) {
					return false;
				}
			}
			return true;
		};

		let sourceLang = event.target.getAttribute('data-lang');
		let uniqueId = Math.ceil(event.timeStamp);
		let now = Date.now();
		let newTagStrList = new Array($eleList.length);
		let i = 0;
		rubies.forEach(obj => {

			let sqry = obj.raw;
			i++;
			let isValid = isValidPhrase($eleList, [...obj.indices]);
			if (isValid) {
				obj.indices.forEach(function(index) {
					newTagStrList[index] = `<span class="u_word_dic" data-hook="tip" data-type="phex" data-lang="${sourceLang}" data-grp='${uniqueId+i}' data-sqry="${sqry}">${$($eleList.get(index)).text()}</span>`;
				});
			}
		});
		let isMobile = getCurrentOptions().isMobile;
		let targetElementNew = null;
		//console.log(`[ray-refine-en.js] engrave, $eleList[0] is`);
		//console.log($eleList[0]);
		//console.log(`[ray-refine-en.js] engrave, $eleList[0].parentNode is`);
		//console.log($eleList[0].parentNode);
		$eleList.each(function(index, value) {
			let parentNode = value.parentNode;
			let html = null;
			if (newTagStrList[index]) {
				html = $.parseHTML(newTagStrList[index]);
				//$(this).before(html);
				//$(this).remove();
			} else {
				let str = `<span class="u_word_dic" data-hook="tip" data-type="arken" data-lang="${sourceLang}">${$($eleList.get(index)).text()}</span>`;
				html = $.parseHTML(str);
				//$(this).before(html);
				//$(this).remove();
			}
			let newNode;
			try {
				/*for (let j=0;j<html.length;j++) {
					let newNode = document.importNode(html[j],true);
					parentNode.insertBefore(newNode,value);
				}*/
				newNode = document.importNode(html[0], true);
				parentNode.insertBefore(newNode, value);
				parentNode.removeChild(value);
			} catch (err) {
				console.log(err.message + "\n툴팁 대상에 오류가 있습니다. 아래 툴팁 대상이 올바른지 확인해보시기 바랍니다. \nerror engrave en target:" + value);
			}

			/*if(isMobile && (index == clickedIdx)){ // if it was originated from mobile touch
				// $(html).trigger("click");
				targetElementNew = newNode;
			}*/
			if (index === clickedIdx) {
				targetElementNew = newNode;
			}

		});

		if (isMobile) {
			//console.log(`[raw-refine-en.js] engrave, trigger mobile click`);
			$(targetElementNew).trigger("click");
		} else {
			//console.log(`[raw-refine-en.js] engrave, trigger mouseover`);
			//$(targetElementNew).trigger("mouseover");
		}

		return targetElementNew;
	}
};


export default markerByLang;
