import $ from 'jQuery';
import {
	getCurrentOptions
} from './config/appConfig.js';
import {
	getRawRefiner
} from './config/importByLang.js';
import tooltipLayerHtmlCache from './Util/tooltip-layer-html-cache.js';

import pseudoSentence, {
	position_nospacelang
} from './Util/search-neighbor'
import trimQuery from './Util/trim-query.js';
import getLayer from './layers.js';
import weaken from "./logic/entry-bold.js";
import {
	delayTime
} from "./Util/util";
import classMap from "./config/codeClassMap";

const aUrlByLang = {
	'ja': '/hanaterm',
	'zh': '/hanalink',
	'en': '/phex'
};

//let rubies = null; //layer html result string

let currentEventId = null;

export async function mouseover(event) {
	if (event.target.nodeName === 'RUBY') {
		//console.log(`[eventHandler-ore.js] mouseover, ignore RUBY element, nodeName = ${event.target.nodeName}`);
		return false;
	}
	if (event.target.nodeName === 'RB') {
		//console.log(`[eventHandler-ore.js] mouseover, ignore RB element, nodeName = ${event.target.nodeName}`);
		return false;
	}
	if (event.target.nodeName === 'RT') {
		//console.log(`[eventHandler-ore.js] mouseover, ignore RT element, nodeName = ${event.target.nodeName}`);
		return false;
	}

	let eventId = event.timeStamp + event.target.textContent;
	currentEventId = eventId;
	//currentEventId = Math.ceil(event.timeStamp);
	//console.log(`[eventHandle-ore.js] mouseover, textContent = ${event.target.textContent}`);

	await delayTime(100);
	if (eventId === currentEventId) {
		//console.log(`[eventHandle-ore.js] mouseover, textContent = ${event.target.textContent}, eventId = ${eventId}`);
		_request(event, eventId);
	} else {
		//console.log(`[eventHandle-ore.js] mouseover event expired, do nothing, eventId = ${eventId}, currentEventId = ${currentEventId}`);
	}

	return true;
}

export function mouseout(event) {
	if (event.target.nodeName === 'RUBY') {
		//console.log(`[eventHandler-ore.js] mouseout, ignore RUBY element, nodeName = ${event.target.nodeName}`);
		return false;
	}
	if (event.target.nodeName === 'RB') {
		//console.log(`[eventHandler-ore.js] mouseout, ignore RB element, nodeName = ${event.target.nodeName}`);
		return false;
	}
	if (event.target.nodeName === 'RT') {
		//console.log(`[eventHandler-ore.js] mouseout, ignore RT element, nodeName = ${event.target.nodeName}`);
		return false;
	}
	currentEventId = null;
	//console.log(`[eventHandle-ore.js] mouseout, textContent = ${event.target.textContent}, currentEventId = ${currentEventId}`);

	return true;
}

function _getParams(event) {
	//console.log(`[eventHandle-ore.js] _getParams`);

	let sourceLang = event.target.getAttribute('data-lang') || event.target.parentNode.getAttribute('data-lang');
	let currentOptions = getCurrentOptions();
	let cid = currentOptions.cid || '';
	let targetLang = currentOptions['lang'][sourceLang]['target'];
	if (currentOptions.multiTab && !!currentOptions.multiTab.length) {
		for (let i = 0; i < currentOptions.multiTab.length; i++) {
			if (currentOptions.multiTab[i].target == targetLang) {
				event.isOtherDict = !!currentOptions.multiTab[i].isOtherDict;
				break;
			}
		}
	}
	let rawQuery;

	if (event.target.getAttribute("data-type") === "quant") {
		//zh,hj
		var {
			$eleList,
			raw,
			clickedIdx
		} = position_nospacelang(event);
		sourceLang = event.target.getAttribute('data-lang') || event.target.parentNode.getAttribute('data-lang');
		rawQuery = trimQuery(event.target.textContent);
		//console.log(`[eventHandle-ore.js] _getParams, quant, clickedIdx = ${clickedIdx}, rawQuery = ${rawQuery}`);
	} else {
		//ja,en
		//console.log(`[eventHandle-ore.js] _getParams, default`);
		//for case that is <ore>A</ore><ore>B</ore><ore>C</ore>
		var {
			$eleList,
			raw,
			clickedIdx
		} = pseudoSentence(event);
		sourceLang = event.target.getAttribute("data-lang");
		rawQuery = trimQuery(event.target.textContent);
	}

	if (!sourceLang) {
		return;
	}

	//console.log(`[eventHandle-ore.js] _getParams, raw = ${raw}`);
	//console.log(`[eventHandle-ore.js] _getParams, rawQuery = ${rawQuery}`);
	return {
		cid,
		$eleList,
		sourceLang,
		targetLang,
		raw,
		rawQuery,
		clickedIdx,
		dataApi: currentOptions.dataApi,
		device: (currentOptions['isMobile']) ? 'm' : 'pc',
		userCode: currentOptions['userCode'],
		layerTarget: currentOptions['layerTarget'],
		layerType: currentOptions['layerType'] || "",
		skin: currentOptions['skin']
	};
}

async function _request(event, eventId) {
	let {
		cid,
		$eleList,
		raw,
		sourceLang,
		dataApi,
		clickedIdx,
		rawQuery,
		targetLang,
		device,
		userCode,
		layerTarget,
		layerType,
		skin
	} = _getParams(event);
	let arkenUrl = dataApi + aUrlByLang[sourceLang] || ''; // ore analysis api
	let prCode = getCurrentOptions()['prCode'];
	// console.log(`[eventHandle-ore.js] _request, arkenUrl = ${arkenUrl}, prCode= ${prCode}, raw = ${raw}, skin = ${skin}`);
	let data = await _getArkens({
		cid,
		arkenUrl,
		sourceLang,
		targetLang,
		raw,
		rawQuery,
		clickedIdx,
		prCode: prCode,
		device,
		skin
	});
	_requestDone({
		event,
		eventId,
		data,
		$eleList,
		sourceLang,
		targetLang,
		clickedIdx,
		device,
		userCode,
		layerTarget,
		layerType,
		skin,
		prCode
	});
}

function _getArkens({
	cid,
	arkenUrl,
	sourceLang,
	targetLang,
	raw,
	clickedIdx,
	rawQuery,
	prCode,
	device,
	skin
}) {
	//console.log(`[eventHandle-ore.js] _getArkens, arkenUrl = ${arkenUrl}`);
	return $.ajax({
		type: "get",
		url: arkenUrl,
		// timeout: 1500,
		timeout: 2000,
		data: $.param({
			cid,
			srcLang: sourceLang,
			tarLang: targetLang,
			raw,
			clickedIdx,
			rawQuery,
			prCode,
			device,
			skin,
			service: 'naver'
		}),
		crossDomain: true,
		dataType: "jsonp",
		error: function(jqXHR, textStatus, errorThrown) {
			console.log(`[eventHandle-ore.js] _getArkens failed, jqXHR.responseText = ${jqXHR.responseText}, jqXHR.status = ${jqXHR.status}`);
			console.log(`[eventHandle-ore.js] _getArkens failed, error = ${textStatus}`);
			console.log(`[eventHandle-ore.js] _getArkens failed, errorThrown = ${errorThrown}`);
		}
	});
}

function _requestDone(args) {
	let {
		event,
		eventId,
		data,
		$eleList,
		sourceLang,
		clickedIdx,
		targetLang,
		device,
		userCode,
		layerTarget,
		layerType,
		skin,
		prCode
	} = args;
	if (eventId !== currentEventId) {
		//console.log(`[eventHandle-ore.js] _requestDone, event expired, eventId = ${eventId}`);
		return;
	}
	if (data && data.rubies) {
		//console.log(`[eventHandle-ore.js] _requestDone, returned data`);
		let {
			searchResult,
			rubies,
			sQuery
		} = data;
		let targetElementNew = _engrave(rubies, event, $eleList, clickedIdx);

		if (searchResult && searchResult.result) {

			// console.log('phex api -> searchResult');
			// console.log(rubies);
			// console.log(searchResult);

			searchResult.isOtherDict = !!event.isOtherDict;
			weaken(sourceLang, sQuery, searchResult);
			let toolTipLayerHtmlStr = getLayer({
				layerType,
				sourceLang,
				targetLang,
				body: searchResult,
				userCode,
				layerTarget,
				device,
				skin,
				prCode
			});

			if (toolTipLayerHtmlStr) {
				//console.log(`[eventHandle-ore.js] _requestDone, layerTarget = ${searchResult.layerTarget}`);
				tooltipLayerHtmlCache.setData([sourceLang, targetLang, sQuery, userCode].join(''), toolTipLayerHtmlStr);
			}
			_highlightOn(targetElementNew);

		}
		//console.timeEnd(_requestDoneTimer);
		//let _engraveTimer = 'time taken by engrave';
		//console.time(_engraveTimer);


		//console.timeEnd(_engraveTimer);
		//console.timeEnd('time spent from mouseover to highlight');

	} else {
		//console.log(`[eventHandle-ore.js] _requestDone, returned null`);
		//rubies = null;
		// $layer = null;
	}
}

function _highlightOn(target) {
	//console.log(`[eventHandle-ore.js] _highlightOn, textContent = ${target.textContent}`);
	let grpId = target.getAttribute('data-grp');
	if (grpId) {
		//console.log(`[eventHandle-ore.js] _highlightOn, group case`);
		$(`span[data-grp='${grpId}']`).addClass(classMap['sHighLightingClass']);
	} else {
		if (target.parentNode && target.parentNode.classList.contains('u_word_dic') && target.parentNode.getAttribute('data-lang') === 'hj') {
			//console.log(`[eventHandle-ore.js] _highlightOn, hj case`);
			target.parentNode.classList.add(classMap['sHighLightingClass']);
		} else {
			//console.log(`[eventHandle-ore.js] _highlightOn, normal case`);
			target.classList.add(classMap['sHighLightingClass']);
		}
	}
}

// <span data-phex data-grp='1' data-sqry="taken off" class="u_word_dic" data-hook="tip" data-type="arken" data-lang="en">off</span>

function _engrave(rubies, event, $eleList, clickedIdx) { // data-type="arken" 태그를 박아넣다.
	//console.log(`[eventHandle-ore.js] _engrave`);
	let lang = event.target.getAttribute('data-lang') || event.target.parentNode.getAttribute('data-lang');
	return getRawRefiner(lang).engrave(rubies, event, $eleList, clickedIdx);
}
