function delayTime(ms){
    if (ms){
        return new Promise(resolve => setTimeout(resolve,ms));
    } else {
        return new Promise(resolve => setTimeout(resolve));
    }
}

export {
    delayTime
}