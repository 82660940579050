import $ from 'jQuery';
import {
	getCurrentOptions,
	setCurrentNsc,
	getCurrentNsc,
	getServiceURL,
	getServiceDomain,
	getNNBCookie
} from './config/appConfig';
import {
	findAscendantNodeByAttribute
} from './Util/domNodeUtil';
import classMap from './config/codeClassMap.js';
import getLayer, {
	getTermsZoomLayer,
	getHanjaZoomLayer,
	getNoResultLayer
} from './layers.js';
import arken_degrade from "./logic/arken-degrade.js";
import weaken from "./logic/entry-bold.js";
import {
	setGeneralLayerPosition,
	setLineLayerPosition,
	setCustomLayerPosition,
	setZoomLayerWidth,
	setHanjaZoomLayerPosition,
	setHanjaTipLayerPosition
} from './logic/layer-position.js';
import {
	sendCloseNclick,
	sendOpenNclick,
	tipNclick
} from './logic/tip-nclick.js';
import {
	setSoundPlayer
} from './Util/audio-player.js';
import tooltipLayerHtmlCache from './Util/tooltip-layer-html-cache.js';
import trimQuery from './Util/trim-query.js';
import isHanjaZoomCase from './logic/is-hanja-zoom-case.js';
import {
	delayTime
} from "./Util/util";
import i18nText from './handlebars-helpers/i18n-message.js';
let toolTipLayerHtmlStr = null; //tipLayer html result string

let currentTipShowedWord = null;
let currentHanjaBlock = null;
let currentMouseoverTarget = null;
let currentMouseoutTarget = null;

let hanja_zoomLayer_session = false;
let current_sessionId = 1;

let currentEventId = null;

export async function mouseover(event) {
	if (event.target.nodeName === 'RUBY') {
		//console.log(`[eventHandler.js] mouseover, ignore RUBY tag`);
		return false;
	}
	if (event.target.nodeName === 'RB') {
		//console.log(`[eventHandler.js] mouseover, ignore RB tag`);
		return false;
	}
	if (event.target.nodeName === 'RT') {
		//console.log(`[eventHandler.js] mouseover, ignore RT tag`);
		return false;
	}
	if (event.target.classList.contains('u_green_dot_active') || event.target.classList.contains('u_active')) {
		//console.log(`[eventHandler.js] mouseover, textContent = ${event.target.textContent}, already highlighted`);
		return;
	}
	//console.log(`[eventHandler.js] mouseover, textContent = ${event.target.textContent}`);

	let eventId = event.timeStamp + event.target.textContent;
	currentEventId = eventId;
	await delayTime(100);
	if (eventId === currentEventId) {
		//console.log(`[eventHandle.js] mouseover, textContent = ${event.target.textContent}, eventId = ${eventId}`);
		_request(event, eventId);
	} else {
		//console.log(`[eventHandle.js] mouseover event expired, do nothing`);
	}
	return true;
}

export function mouseout(event) {
	if (event.target.nodeName === 'RUBY') {
		//console.log(`[eventHandler.js] mouseout, ignore RUBY tag`);
		return false;
	}
	if (event.target.nodeName === 'RB') {
		//console.log(`[eventHandler.js] mouseout, ignore RB tag`);
		return false;
	}
	if (event.target.nodeName === 'RT') {
		//console.log(`[eventHandler.js] mouseout, ignore RT tag`);
		return false;
	}
	//console.log(`[eventHandler.js] mouseout, textContent = ${event.target.textContent}`);

	currentEventId = null;
	let mouseoutWord = _getWordTarget(event.target);
	//console.log(`[eventHandler.js] mouseout, mouseoutWord.textContent = ${mouseoutWord.textContent}`);
	//if tipLayer not showed or
	if (!$('#' + classMap['tipLayerId']).get(0)) {
		//console.log(`[eventHandler.js] mouseout, no tipLayer`);
		//_highlightOff(event.target);
		_highlightOff(mouseoutWord);
	} else if (currentTipShowedWord != mouseoutWord) {
		//console.log(`[eventHandler.js] mouseout, not targeted word`);
		//_highlightOff(event.target);
		_highlightOff(mouseoutWord);
	} else {
		//console.log(`[eventHandler.js] mouseout, do nothing`);
	}

	toolTipLayerHtmlStr = null;
	return true;
}

export function mouseoverHanja(event) {
	//console.log(`[eventHandler.js] mouseoverHanja, event.target.textContent = ${event.target.textContent}, event.target.outerHTML = ${event.target.outerHTML}`);
	currentMouseoverTarget = event.target;
	event.target.parentNode.classList.add(classMap['sHighLightingClass']);
}

export function mouseoutHanja(event) {
	//console.log(`[eventHandler.js] mouseoutHanja, event.target.textContent = ${event.target.textContent}, event.target.outerHTML = ${event.target.outerHTML}`);
	if (event.target.nodeName === 'RUBY') {
		//console.log(`[eventHandler.js] mouseout, ignore RUBY tag`);
		return false;
	}
	if (event.target.nodeName === 'RB') {
		//console.log(`[eventHandler.js] mouseout, ignore RB tag`);
		return false;
	}
	if (event.target.nodeName === 'RT') {
		//console.log(`[eventHandler.js] mouseout, ignore RT tag`);
		return false;
	}
	//console.log(`[eventHandler.js] mouseoutHanja, textContent = ${event.target.textContent}`);
	currentMouseoutTarget = event.target;
	currentEventId = null;
	let mouseoutWord = _getWordTarget(event.target);
	//if tipLayer not showed
	if (!$('#' + classMap['tipLayerId']).get(0)) {
		//console.log(`[eventHandler.js] mouseoutHanja, no tipLayer`);
		//_highlightOff(event.target);
		_highlightOff(mouseoutWord);
	} else if (event.target.parentNode.isSameNode(currentHanjaBlock) === false) {
		//console.log(`[eventHandler.js] mouseoutHanja, not targeted word, mouseoutWord.textContent = ${mouseoutWord.textContent}, currentHanjaBlock.textContent = ${currentHanjaBlock.textContent}`);
		_highlightOff(mouseoutWord);
	}

	/*if (timeOutId) {
	    clearTimeout(timeOutId);
	    timeOutId = null;
	}*/
	toolTipLayerHtmlStr = null;
	return true;
}

export function mouseClick(event) {
	//console.log(`[eventHandler.js] mouseClick, textContent = ${event.target.textContent}, event.target.outerHTML = ${event.target.outerHTML}`);
	let $target = $(event.target);
	let currentOptions = getCurrentOptions();
	let sourceLang = $target.attr('data-lang') || $target.parent().attr('data-lang');
	let targetLang = currentOptions['lang'][sourceLang]['target'];
	let nsCode = currentOptions['lang'][sourceLang]['nsc'];
	let sQuery = null;
	if ($target.attr('data-type') === 'phex') { // phex query
		sQuery = $target.attr('data-sqry');

	} else if (sourceLang === 'ja') {
		sQuery = $target.attr('data-sqry');
	} else if (sourceLang === 'hj') {
		if (nsCode === 'dic.tt.cck' || nsCode === 'mdic.tt.cck') {
			sourceLang = 'cc'
		}
		sQuery = trimQuery($target.text()); //arken query
	} else {
		sQuery = trimQuery($target.text()); //arken query
	}
	let userCode = currentOptions['userCode'];
	let cacheKey = [sourceLang, targetLang, sQuery, userCode].join('');
	let cachedData = tooltipLayerHtmlCache.getData(cacheKey);
	if (cachedData) {
		toolTipLayerHtmlStr = cachedData;
		show(event);
	}
}
export function mobileClick(event, isZoomClicked) {
	//console.log(`[eventHandler.js] mobileClick, textContent = ${event.target.textContent}, event.target.outerHTML = ${event.target.outerHTML}`);
	event.stopPropagation();
	if (!$(event.target).hasClass('u_word_zoom')) {
		hanja_zoomLayer_session = false;
	}

	toolTipLayerHtmlStr = null;
	let eventId = event.timeStamp + event.target.textContent;
	currentEventId = eventId;
	_request(event, eventId, isZoomClicked);
}

//모바일 지식백과, 한자사전 전용
export function hanjaChunkClick(event) {
	hanja_zoomLayer_session = true;
	currentHanjaBlock = event.target.parentNode;
	//console.log(`[eventHandler.js] hanjaChunkClick, currentHanjaBlock = ${currentHanjaBlock.textContent}`);
	closeLayer(event);
	if (event.target.parentNode.children.length === 1 && getCurrentOptions().prCode === 'dict') {
		// 한자 사전 글짜수가 한개일때 zoomlayer 비노출 처리
		/*setTimeout(function () {
		    mobileClick(event,false);
		});*/
		mobileClick(event, false);
	} else {
		_requestZoomLayer(event);
	}

}

export function closeLayer(event) {
	if (!$(event.target).hasClass("my_multi_tooltip_btn")) {
		event.stopPropagation();
		//console.log(`[eventHandler.js] close all and highlight off all`);
		_closeTip();
		_closeZoom();
		_highlightOffAll(); // clear all the highlight
	}
}

function _getWordTarget(target) {
	if (target.getAttribute('data-hook') === 'tip') {
		return target;
	} else if (target.getAttribute('data-lang') === 'hj' && isHanjaZoomCase()) {
		return target;
	} else {
		return findAscendantNodeByAttribute(target, 'data-hook', 'tip');
	}
}

function _getParams(event) {
	//console.log(`[eventHandler.js] _getParams`);
	let querytype = 'arken';
	let $target = $(event.target);

	let sourceLang = $target.attr('data-lang') || $target.parent().attr('data-lang');
	if (!sourceLang) {
		return;
	}
	let currentOptions = getCurrentOptions();
	let cid = currentOptions.cid || '';
	let nsCode = currentOptions['lang'][sourceLang]['nsc'];
	setCurrentNsc(nsCode); // for nclick nsCode
	let targetLang = currentOptions['lang'][sourceLang]['target'];
	if (currentOptions.multiTab && !!currentOptions.multiTab.length) {
		for (let i = 0; i < currentOptions.multiTab.length; i++) {
			if (currentOptions.multiTab[i].target == (event.changeTargetLang || targetLang)) {
				event.isOtherDict = !!currentOptions.multiTab[i].isOtherDict;
				break;
			}
		}
	}
	let rawQuery = trimQuery($target.text());
	let sQuery = null;
	if ($target.attr('data-type') === 'phex') { // phex query
		sQuery = $target.attr('data-sqry');
		querytype = 'phex';
	} else if (sourceLang === 'ja') {
		sQuery = $target.attr('data-sqry');
	} else if (sourceLang === 'hj') {
		if (nsCode === 'dic.tt.cck' || nsCode === 'mdic.tt.cck') {
			sourceLang = 'cc'
		}
		sQuery = trimQuery($target.text()); //arken query
	} else {
		sQuery = trimQuery($target.text()); //arken query
	}
	//console.log(`[eventHandler.js] _getParams, sQuery = ${sQuery}, rawQuery = ${rawQuery}, sourceLang = ${sourceLang}`);
	if (event.multiTabClick) {
		if (event.changeTargetLang) {
			targetLang = event.changeTargetLang;
		}
		sQuery = _delSpecialChar(sQuery);
		rawQuery = _delSpecialChar(rawQuery);
	}
	return {
		cid,
		querytype,
		sourceLang,
		targetLang,
		sQuery,
		rawQuery,
		dataApi: currentOptions.dataApi,
		sscode: nsCode,
		device: (currentOptions['isMobile']) ? 'm' : 'pc',
		userCode: currentOptions['userCode'],
		layerTarget: currentOptions['layerTarget'],
		layerType: currentOptions['layerType'] || "",
		skin: currentOptions['skin']
	};

}

async function _request(event, eventId, isZoomClicked) {
	//console.log(`[eventHandler.js] _request`);
	let {
		cid,
		querytype,
		sQuery,
		rawQuery,
		sourceLang,
		targetLang,
		device,
		userCode,
		dataApi,
		layerTarget,
		layerType,
		skin
	} = _getParams(event);
	console.log("--eventId--->" + eventId);
	let dataUrl = `${dataApi}/datajsonp/${sourceLang}/${targetLang}/${device}/${querytype}`;
	let cacheKey = [sourceLang, targetLang, sQuery, userCode].join('');
	let prCode = getCurrentOptions()['prCode'];
	//console.log(`[eventHandler.js] _request, dataUrl = ${dataUrl}, prCode = ${prCode}, skin = ${skin}`);
	let data = await _getData({
		cid,
		dataUrl,
		cacheKey,
		entryName: sQuery,
		rawQuery,
		prCode: prCode,
		skin
	});
	_requestDone({
		event,
		eventId,
		isZoomClicked,
		data,
		sQuery,
		rawQuery,
		sourceLang,
		targetLang,
		device,
		userCode,
		layerTarget,
		layerType,
		skin,
		prCode
	});
}

function _getData({
	cid,
	dataUrl,
	cacheKey,
	entryName,
	rawQuery,
	prCode,
	skin,
	sscode,
	serviceURL,
	serviceDomain,
	NNB
}) {
	//console.log(`[eventHandler.js] _getData, dataUrl = ${dataUrl}, serviceURL = ${serviceURL}, serviceDomain = ${serviceDomain}, NNB = ${NNB}`);
	let cachedValue = tooltipLayerHtmlCache.getData(cacheKey);
	if (cachedValue) {
		//console.log(`[eventHandler.js] _getData, has cachedValue`);
		return $.Deferred().resolve({
			data: cachedValue,
			fromCache: true
		});
	}
	//console.log(`[eventHandler.js] _getData, dataUrl = ${dataUrl}, entryName = ${entryName}, rawQuery = ${rawQuery}, prCode = ${prCode}, sscode = ${sscode}`);
	return $.ajax({
		type: "get",
		url: dataUrl,
		timeout: 1500,
		data: $.param({
			cid,
			entryName,
			rawQuery,
			prCode,
			sscode,
			skin,
			serviceURL,
			serviceDomain,
			service: 'naver',
			NNB
		}),
		crossDomain: true,
		dataType: "jsonp",
		error: function(jqXHR, textStatus, errorThrown) {
			console.log(`[eventHandler.js] _getData failed, jqXHR.responseText = ${jqXHR.responseText}, jqXHR.status = ${jqXHR.status}`);
			console.log(`[eventHandler.js] _getData failed, error = ${textStatus}`);
			console.log(`[eventHandler.js] _getData failed, errorThrown = ${errorThrown}`);
		}
	});
}

function _requestDone(args) {
	let {
		event,
		eventId,
		isZoomClicked,
		data,
		sQuery,
		rawQuery,
		sourceLang,
		targetLang,
		device,
		userCode,
		layerTarget,
		layerType,
		skin,
		prCode
	} = args;
	if (eventId !== currentEventId && event.type !== 'click') {
		//console.log(`[eventHandle.js] _requestDone, event expired, eventId = ${eventId}`);
		return;
	}
	let currentOptions = getCurrentOptions();
	//from cache do differently
	if (data && data.fromCache) {
		//console.log(`[eventHandler.js] _requestDone, from cache`);
		toolTipLayerHtmlStr = data.data;
		// _highlightOn(event.target);
		if (currentOptions.lang["hj"] && currentOptions.lang["hj"]['zoomLayer'] && currentOptions.layerType === 'greendot') {
			show(event, isZoomClicked);
		} else if (getCurrentOptions()['isMobile']) {
			show(event, isZoomClicked);
		} else if (event.multiTabClick) {
			show(event, isZoomClicked);
		} else {
			_highlightOn(event.target);
		}
	} else if (data && data.data && data.data.result) {
		//console.log(`[eventHandler.js] _request, not from cache`);
		let body = data.data;
		if (body) {
			body.isOtherDict = !!event.isOtherDict;
		}
		// if from phex-> arken degrade
		if (body.result.isArkenDegrade) {
			//console.log(`datajsonp api-> arkenDegrade ${rawQuery}`);
			sQuery = rawQuery;
			arken_degrade(event);
		}
		weaken(sourceLang, sQuery, body);
		toolTipLayerHtmlStr = getLayer({
			layerType,
			sourceLang,
			targetLang,
			body,
			userCode,
			layerTarget,
			device,
			skin,
			prCode
		});

		tooltipLayerHtmlCache.setData([sourceLang, targetLang, sQuery, userCode].join(''), toolTipLayerHtmlStr);

		if (currentOptions.lang["hj"] && currentOptions.lang["hj"]['zoomLayer'] && currentOptions.layerType === 'greendot') {
			show(event, isZoomClicked);
		} else if (currentOptions['isMobile']) {
			show(event, isZoomClicked);
		} else if (event.multiTabClick) {
			show(event, isZoomClicked);
		} else {
			_highlightOn(event.target);
		}

	} else {
		//console.log(`[eventHandler.js] _request, no result`);
		if (currentOptions.lang["hj"] && currentOptions.lang["hj"]['zoomLayer'] && currentOptions.layerType === 'greendot') {
			toolTipLayerHtmlStr = getNoResultLayer(layerType, targetLang);
			tooltipLayerHtmlCache.setData([sourceLang, targetLang, sQuery, userCode].join(''), toolTipLayerHtmlStr);
			show(event, isZoomClicked);
		} else {
			if (event && event.multiTabClick) {
				toolTipLayerHtmlStr = '<div class="u_no_data" lang="' + skin + '">' + i18nText['multiTabNoResult'][skin || 'en'](sQuery, sourceLang) + '</div>'; //getMultiTabNoResultLayer(layerType, "multiNoR", sourceLang, skin, sQuery);
				$('#tooltipLayer_dict .u_btn_more_wrap,.u_helpdict_area,.u_helpdict_notice,.u_no_data').remove();
				$("#tooltipLayer_dict").append(toolTipLayerHtmlStr);
				_initMultlTab(event, currentOptions);
				_attachEvent();
			} else {
				toolTipLayerHtmlStr = null;
			}
		}
	}
}
//mytooltip:enen显示tip框
export function show(event, isZoomClicked) {
	event.stopPropagation();
	let wordTarget = _getWordTarget(event.target);
	/*if ($('#' + classMap['tipLayerId']).get(0) && wordTarget === currentTipShowedWord) {
	    //console.log(`[eventHandler.js] show, ignore same word`);
	    return false;
	}*/
	//console.log(`[eventHandler.js] show tip layer!!---------------`);
	const currentOptions = getCurrentOptions();

	_closeTip(event);

	if (toolTipLayerHtmlStr) {
		_highlightOn(event.target);
		let $layer = $.parseHTML(toolTipLayerHtmlStr);
		let newNode = document.importNode($layer[0], true);
		let nsCode = getCurrentNsc();
		//console.log(`[eventHandler.js] show, prCode = ${currentOptions.prCode}, nsc = ${nsCode}`);
		if (currentOptions.layerPosition) {
			document.querySelector(currentOptions.layerPosition).appendChild(newNode);
		} else if (currentOptions.prCode === 'linedict' && (nsCode === 'Mlinedict.tt_lej' || nsCode === 'Mlinedict.tt_ljj')) {
			//https://oss.navercorp.com/skywalker/dict_platform/issues/582 라인사전 보조사전 스크롤 안되는 이슈
			document.querySelector('#wrap #container #content').appendChild(newNode);
		} else {
			//console.log(`append to body`);
			document.body.appendChild(newNode);
		}

		sendOpenNclick();
		if (currentOptions.prCode !== 'linedict') {
			_sendLaimLog(event);
		}
		//layer positioning
		_attachEvent();
		let $tipLayer = $('#' + classMap['tipLayerId']);
		if (currentOptions.layerType === "greendot") {
			if (currentOptions.isMobile) {
				//console.log(`[eventHandler.js] show, device is mobile`);
				$tipLayer.removeClass('pc');
			} else {
				//console.log(`[eventHandler.js] show, device is pc`);
				$tipLayer.addClass('pc');
			}
		}
		let soureLang = wordTarget.getAttribute('data-lang');
		if (nsCode === 'dic.tt.cck' || nsCode === 'mdic.tt.cck') {
			if (event.target.parentNode.children.length === 1) {
				if (currentOptions.layerPosition) {
					setCustomLayerPosition(wordTarget, $tipLayer, currentOptions.layerPosition);
				} else {
					setGeneralLayerPosition($(wordTarget), $tipLayer, isZoomClicked);
				}
			} else {
				setHanjaTipLayerPosition(wordTarget, $tipLayer);
			}
		} else if (nsCode === 'mdic.tt.thk') {
			setHanjaTipLayerPosition(wordTarget, $tipLayer);
		} else if (currentOptions.prCode === 'linedict' && (nsCode === 'Mlinedict.tt_lej' || nsCode === 'Mlinedict.tt_ljj')) {
			setLineLayerPosition(wordTarget, $tipLayer);
		} else if (currentOptions.layerPosition) {
			//document.querySelector(currentOptions.layerPosition).appendChild(newNode);
			setCustomLayerPosition(wordTarget, $tipLayer, currentOptions.layerPosition);
		} else {
			if (!currentOptions.useScrollLayer) {
				setGeneralLayerPosition(wordTarget, $tipLayer, isZoomClicked);
			} else { //https://oss.navercorp.com/skywalker/accentia-frontend/issues/2253
				if (!currentOptions.isMobile && location.href.indexOf("/talk/") != -1) {
					$("#tooltipLayer_dict").css("top", $(wordTarget).offset().top - $("#tooltipLayer_dict").height());
					$("#tooltipLayer_dict").css("transform", "translateY(-16px)");
				}
			}
		}

		//set sound
		setSoundPlayer();
		currentTipShowedWord = wordTarget;
		//console.log(`[eventHandler.js] show, currentTipShowedWord.textContent = ${currentTipShowedWord.textContent}`);
	}
	_attachZoomEvent();
	// alert(jQuery(".my_enko").length);
	$(".my_multi_tooltip_btn").off().on("click", function() {
		event.changeTargetLang = $(this).attr("targetLang") || "en";
		event.multiTabClick = true;
		let eventId = event.timeStamp + event.target.textContent;
		currentEventId = eventId;
		_request(event, eventId, false);
	});

	_initMultlTab(event, currentOptions);
	return true;
}

function _delSpecialChar(con) {
	if (con) {
		con = con.replace(/·/g, "");
	} else {
		con = "";
	}
	return con;
}

function _sendLaimLog(event) {

	let {
		sQuery,
		sourceLang,
		targetLang,
		device,
		dataApi,
		layerTarget,
		sscode
	} = _getParams(event);
	// ${hosturl}/datajsonp/ko/en/en/${pc||m}
	let dataUrl = `${dataApi}/datajsonp/laimlog/${sourceLang}/${targetLang}/${device}`;
	let NNB = getNNBCookie() || '';
	let serviceDomain = getServiceDomain() || '';
	let serviceURL = getServiceURL() || '';
	//console.log(`[eventHandler.js] _sendLaimLog, dataUrl = ${dataUrl}, sscode= ${sscode}, serviceURL = ${serviceURL}, serviceDomain = ${serviceDomain}, NNB = ${NNB}`);
	//send laim log with sscode
	_getData({
		dataUrl,
		entryName: sQuery,
		prCode: getCurrentOptions()['prCode'],
		layerTarget,
		sscode,
		serviceURL,
		serviceDomain,
		NNB
	}).done(() => {}).fail(() => {});
}

function _attachEvent() {
	//console.log(`[eventHandler.js] _attachEvent`);
	let $layer = $('#' + classMap['tipLayerId']);

	$layer.on('click', function(e) {
		//console.log(`[eventHandler.js] layer on click`);
		e.stopPropagation();
	});
	//nclick event handler
	$layer.on('click', '[data-nclick]', $.proxy(tipNclick, null));

	$('.tooltipLayer_add_to_wordbook_button').on('click', (event) => {
		let currentOptions = getCurrentOptions();
		if (currentOptions.callbackFunc) {
			currentOptions.callbackFunc(event, {
				entryId: event.target.parentNode.getAttribute('data-entryId'),
				dictType: getCurrentNsc()
			});
		}
	});

	$('button.u_helpdict_close').on('click', (event) => {
		sendCloseNclick();
		closeLayer(event);
		//_closeTip(event);
	});

}

//모바일 지식백과, 한자사전 용
function _requestZoomLayer(event) {
	//console.log(`[eventHandler.js] _requestZoomLayer`);
	let {
		sQuery,
		quantumIndex,
		layerType
	} = _getParamsForZoomLayer(event);
	/*setTimeout(function () {
	    let currentOptions = getCurrentOptions();
	    let nsCode = currentOptions.lang.hj.nsc;
	    if (layerType === 'greendot' && nsCode === 'dic.tt.cck') {
	        toolTipLayerHtmlStr = getHanjaZoomLayer({layerType, hanjaList: sQuery, quantumIndex});
	        quantumIndex = quantumIndex - getCurrentOptions().hanjaZoomStartIndex;
	    } else {
	        toolTipLayerHtmlStr = getTermsZoomLayer({layerType, hanjaList: sQuery, quantumIndex});
	    }
	    //_highlightOn(event.target);
	    _showZoom(event, quantumIndex);
	});*/
	let currentOptions = getCurrentOptions();
	let nsCode = currentOptions.lang.hj.nsc;
	if (layerType === 'greendot' && (nsCode === 'dic.tt.cck' || nsCode === 'mdic.tt.cck')) {
		toolTipLayerHtmlStr = getHanjaZoomLayer({
			layerType,
			hanjaList: sQuery,
			quantumIndex
		});
		quantumIndex = quantumIndex - getCurrentOptions().hanjaZoomStartIndex;
	} else {
		toolTipLayerHtmlStr = getTermsZoomLayer({
			layerType,
			hanjaList: sQuery,
			quantumIndex
		});
	}
	//_highlightOn(event.target);
	_showZoom(event, quantumIndex);
}

function _getParamsForZoomLayer(event) {
	//console.log(`[eventHandler.js] _getParamsForZoomLayer`);
	let $target = $(event.target);
	let $directParent = null;
	let quantumIndex = 0;
	if (event.target !== currentMouseoverTarget && event.target.classList.contains('u_word_dic')) {
		$directParent = $target;
		quantumIndex = $directParent.children().index($(currentMouseoverTarget));
	} else {
		$directParent = $target.parent();
		quantumIndex = $directParent.children().index($target);
	}

	let sQuery = $directParent.text();
	//event.target = $directParent.get(0); // replace child with parent
	let currentOptions = getCurrentOptions();
	return {
		sQuery,
		dataApi: currentOptions.dataApi,
		quantumIndex,
		layerType: currentOptions['layerType'] || ""
	};

}

function _showZoom(event, quantumIndex = 0) {
	//console.log('[eventHandler.js] _showZoom');
	event.stopPropagation();
	_closeZoom();

	let target = null;
	if (event.target !== currentMouseoverTarget && event.target.classList.contains('u_word_dic')) {
		target = currentMouseoverTarget;
	} else {
		target = event.target;
	}
	if (toolTipLayerHtmlStr) {
		_highlightOn(target.parentNode);
		let layerPosition = getCurrentOptions().layerPosition;
		let $layer = $.parseHTML(toolTipLayerHtmlStr);
		for (let j = 0; j < $layer.length; j++) {
			let newNode = document.importNode($layer[j], true);
			if (layerPosition) {
				//console.log('[eventHandler.js] _showZoom, append to special element');
				document.querySelector(layerPosition).appendChild(newNode);
			} else {
				//console.log('[eventHandler.js] _showZoom, append to body');
				document.body.appendChild(newNode);
			}
		}

		//$('body').append($layer);

		//layer positioning
		_attachZoomEvent();
		setZoomLayerWidth($(target.parentNode));
		setHanjaZoomLayerPosition(target);

		let $word = $(classMap['zoomHanja'] + `:eq(${quantumIndex})`);
		$word.trigger('click');
	}
	//set sound

}

function _attachZoomEvent() {
	//console.log(`[eventHandler.js] _attachZoomEvent`);
	$('#' + classMap['zoomLayerId']).on('click', classMap['zoomHanja'], function(event) {

		let target = document.querySelector(classMap['zoomHanja'] + classMap['sHighLightingClass_selector']);
		if (target) {
			//console.log(`[eventHandler.js] _attachZoomEvent, _highlightOff, target`);
			_highlightOff(target);
		}
		_highlightOn(event.target);
		mobileClick(event, true);

	});
	$('#hanja_zoom_prev_button').on('click', _clickHanjaZoomPrev);

	$('#hanja_zoom_next_button').on('click', _clickHanjaZoomNext);

	$('#' + classMap['zoomLayerId']).on('click', function(e) {
		e.stopPropagation()
	});
}

function _clickHanjaZoomPrev(e) {
	//console.log(`click zoom prev`);
	let currentOptions = getCurrentOptions();
	let prevStartIndex = currentOptions.hanjaZoomStartIndex - 7;
	let newArray = null;
	//let kids = $('div#tooltipLayer_hanja_zoom_list .u_word_zoom').children();
	let kids = $(classMap['zoomHanja']);
	if (prevStartIndex < 0) {
		//console.log(`prev 少于7个`);
		currentOptions.hanjaZoomStartIndex = 0;
		newArray = currentOptions.hanjaZoomArray.slice(0, 7);
		kids.each(function(index) {
			$(this).text(newArray[index]);
		});
		$('#hanja_zoom_next_button').addClass('u_active');
		$('#hanja_zoom_next_blank').addClass('u_active');
		$('#hanja_zoom_prev_button').removeClass('u_active');
		$('#hanja_zoom_prev_blank').removeClass('u_active');
		//$('#tooltipLayer_hanja_zoom_list').children().eq(prevStartIndex + 6).trigger('click');

	} else if (prevStartIndex === 0) {
		//console.log(`prev 刚好还有7个`);
		currentOptions.hanjaZoomStartIndex = 0;
		newArray = currentOptions.hanjaZoomArray.slice(0, 7);
		kids.each(function(index) {
			$(this).text(newArray[index]);
		});
		$('#hanja_zoom_next_button').addClass('u_active');
		$('#hanja_zoom_next_blank').addClass('u_active');
		$('#hanja_zoom_prev_button').removeClass('u_active');
		$('#hanja_zoom_prev_blank').removeClass('u_active');
		//$('#tooltipLayer_hanja_zoom_list').children().eq(6).trigger('click');
	} else {
		//console.log(`prev 还有多于7个`);
		currentOptions.hanjaZoomStartIndex = prevStartIndex;
		newArray = currentOptions.hanjaZoomArray.slice(prevStartIndex, prevStartIndex + 7);
		kids.each(function(index) {
			$(this).text(newArray[index]);
		});
		$('#hanja_zoom_next_button').addClass('u_active');
		$('#hanja_zoom_next_blank').addClass('u_active');
		//$('#tooltipLayer_hanja_zoom_list').children().eq(6).trigger('click');
	}
	kids.first().trigger('click');

}

function _clickHanjaZoomNext(e) {
	//console.log(`click zoom next`);
	let currentOptions = getCurrentOptions();
	let nextStartIndex = currentOptions.hanjaZoomStartIndex + 7;
	let newArray = null;
	//let kids = $('#tooltipLayer_hanja_zoom_list').children();
	let kids = $(classMap['zoomHanja']);
	if (nextStartIndex + 6 > currentOptions.hanjaZoomArray.length - 1) {
		//console.log(`next 少于7个`);
		currentOptions.hanjaZoomStartIndex = currentOptions.hanjaZoomArray.length - 7;
		newArray = currentOptions.hanjaZoomArray.slice(currentOptions.hanjaZoomStartIndex);
		kids.each(function(index) {
			$(this).text(newArray[index]);
		});
		$('#hanja_zoom_next_button').removeClass('u_active');
		$('#hanja_zoom_next_blank').removeClass('u_active');
		$('#hanja_zoom_prev_button').addClass('u_active');
		$('#hanja_zoom_prev_blank').addClass('u_active');
		//$('#tooltipLayer_hanja_zoom_list').children().eq(nextStartIndex - currentOptions.hanjaZoomStartIndex).trigger('click');
	} else if (nextStartIndex + 6 === currentOptions.hanjaZoomArray.length - 1) {
		//console.log(`next 刚好还有7个`);
		currentOptions.hanjaZoomStartIndex = nextStartIndex;
		newArray = currentOptions.hanjaZoomArray.slice(nextStartIndex, nextStartIndex + 7);
		kids.each(function(index) {
			$(this).text(newArray[index]);
		});
		$('#hanja_zoom_next_button').removeClass('u_active');
		$('#hanja_zoom_next_blank').removeClass('u_active');
		$('#hanja_zoom_prev_button').addClass('u_active');
		$('#hanja_zoom_prev_blank').addClass('u_active');
		//$('#tooltipLayer_hanja_zoom_list').children().eq(0).trigger('click');
	} else {
		//console.log(`next 还有多于7个`);
		currentOptions.hanjaZoomStartIndex = nextStartIndex;
		newArray = currentOptions.hanjaZoomArray.slice(nextStartIndex, nextStartIndex + 7);
		kids.each(function(index) {
			$(this).text(newArray[index]);
		});
		$('#hanja_zoom_prev_button').addClass('u_active');
		$('#hanja_zoom_prev_blank').addClass('u_active');
		//$('#tooltipLayer_hanja_zoom_list').children().eq(0).trigger('click');
	}
	kids.last().trigger('click');
}

function _highlightOn(target) {
	//console.log(`[eventHandler.js] _highlightOn, textContent = ${target.textContent}`);
	let grpId = target.getAttribute('data-grp');
	if (grpId) {
		//console.log(`[eventHandler.js] _highlightOn, group case`);
		$(`span[data-grp='${grpId}']`).addClass(classMap['sHighLightingClass']);
	} else {
		if (target.parentNode && target.parentNode.classList.contains('u_word_dic') && target.parentNode.getAttribute('data-lang') === 'hj') {
			//console.log(`[eventHandler.js] _highlightOn, hj case`);
			target.parentNode.classList.add(classMap['sHighLightingClass']);
		} else {
			//console.log(`[eventHandler.js] _highlightOn, normal case`);
			target.classList.add(classMap['sHighLightingClass']);
		}
	}
}

function _highlightOff(target) {
	//console.log(`[eventHandler.js] _highlightOff, target.textContent = ${target.textContent}`);
	let grpId = target.getAttribute('data-grp');
	if (grpId) {
		$(`span[data-grp='${grpId}']`).removeClass(classMap['sHighLightingClass']);
	} else {
		target.classList.remove(classMap['sHighLightingClass']);
	}
	//$target.removeClass(classMap['sHighLightingClass']);
}

function _highlightOffAll() {
	//element with highlight class
	//console.log(`[eventHandler.js] _highlightOffAll`);
	$(classMap['sHighLightingClass_selector']).removeClass(classMap['sHighLightingClass']);
}

function _closeTip() {
	//event.stopPropagation();
	//console.log(`[eventHandler.js] _closeTip`);
	$('#' + classMap['tipLayerId']).remove();
	if (!hanja_zoomLayer_session) {
		//console.log(`[eventHandler.js] _closeTip, highlightOff`);
		let selector = classMap['sTargetClass'] + classMap['sHighLightingClass_selector'];
		//console.log(`selector = ${selector}`);
		let target = document.querySelector(selector);
		if (target) {
			//console.log(`[eventHandler.js] _closeTip, highlightOff, target`);
			_highlightOff(target);
		}
		if (currentTipShowedWord) {
			//console.log(`[eventHandler.js] _closeTip, highlightOff, currentTipShowedWord`);
			_highlightOff(currentTipShowedWord);
		}
		//_highlightOff($(classMap['sTargetClass'] + classMap['sHighLightingClass_selector']));
	}
}

function _closeZoom() {
	//console.log(`[eventHandler.js] _closeZoom`);
	$('#' + classMap['zoomLayerId']).remove();
	return true;
}

//初始化multiTooltip tab选中状态（高亮）
function _initMultlTab(event, currentOptions) {
	let myTargetLang = event.changeTargetLang;
	if (!myTargetLang) {
		let $target = $(event.target);
		let sourceLang = $target.attr('data-lang') || $target.parent().attr('data-lang');
		if (!sourceLang) {
			return;
		}
		myTargetLang = currentOptions['lang'][sourceLang]['target'];
	}
	$(".my_multi_tooltip_btn").each(function() {
		if ($(this).attr('targetLang') == myTargetLang) {
			$(this).text($(this).attr('fullName'));
			$(this).attr("aria-selected", "true");
		} else {
			$(this).text($(this).attr('shortName'));
			$(this).attr("aria-selected", "false");
		}
	});
}
