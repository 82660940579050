import {getCurrentOptions} from '../config/appConfig.js';
import {singleHanja_marking} from './raw-refine-hj.js'
import $ from 'jQuery';

function template_ore_marking(sMatchedText) {
    return `<span data-type="ore" data-lang="zh">${singleHanja_marking(sMatchedText)}</span>`;
}

const sPatternCJKRadicalsSupplement = "\u2E80-\u2EFF";	// 한자부수
const sPatternKangxiRadicals = "\u2F00-\u2FDF";	// 강희자전 부수
const sPatternCJKUnifiedIdeographsExtensionA = "\u3400-\u4DBF";	// 한자확장
const sPatternCJKUnifiedIdeographs = "\u4E00-\u9FFF";	// 한중일 공통한자
const sPatternCJKCompatibilityIdeographs = "\uF900-\uFAFF";	// 한중일 호환
// const sNumber = '0-9';
let defaultPattern = "[" + sPatternCJKRadicalsSupplement + sPatternKangxiRadicals + sPatternCJKUnifiedIdeographsExtensionA + sPatternCJKUnifiedIdeographs + sPatternCJKCompatibilityIdeographs + "]";

const markerByLang = {
    _pattern: null,
    _pattern_legacy: null,
    sourceLang: "zh",
    getPattern: function () {
        // let sQuantifiersPattern = "{1}";		// 1글자만 고정 탐색
        let minWordLength = getCurrentOptions().lang["zh"].minWordLength;
        if (minWordLength === undefined) minWordLength = 1;
        let sQuantifiersPattern = "{" + minWordLength + ",}";
        let sPattern = defaultPattern + sQuantifiersPattern;
        this._pattern = new RegExp(sPattern, "ig");

        return this._pattern;
    },
    getPattern_legacy: function () {
        let minWordLength = getCurrentOptions().lang["zh"].minWordLength;
        if (minWordLength === undefined) minWordLength = 1;
        let sQuantifiersPattern = "{" + minWordLength + ",}";
        let sPattern = "(<a [^>]+>([\\s\\S]*?)<\/a>|<span class=\"u_word_dic[^>]+>([\\s\\S]*?)<\/span>|<script[^>]*>([\\s\\S]*?)<\/script>|<style[^>]*>([\\s\\S]*?)<\/style>|<[^>]+>|" + defaultPattern + sQuantifiersPattern + ")";
        this._pattern_legacy = new RegExp(sPattern, "ig");
        
        return this._pattern_legacy;
    },
    mark: function (originalTextContent) {
        //console.log(`[raw-refine-zh.js] mark, original textContent = ${originalTextContent}`);
        let regExp = this._pattern || this.getPattern();
        let isMatched = false;
        
        let reformedTextContent = originalTextContent.replace(regExp, function (match) {
            //let sMatchedText = match;
            //console.log(`[raw-refine-zh.js] mark, match = ${match}`);
            isMatched = true;
            return template_ore_marking(match);
        });

        //console.log(`[raw-refine-zh.js] mark, reformed textContent = ${reformedTextContent}`);
        return {
            textContent: reformedTextContent,
            isMatched: isMatched
        };
    },
    
    mark_legacy: function (sHtml) {
        let oPattern = this._pattern_legacy || this.getPattern_legacy();
        sHtml = sHtml.replace(oPattern, function ($1) {
            let sMatchedText = $1;
            if (sMatchedText.charAt(0) == '<' && sMatchedText.charAt(sMatchedText.length - 1) == '>') {
                return sMatchedText;
            }
            return template_ore_marking(sMatchedText);
        });
        
        return sHtml;
    },
    engrave: function (rubies, event, $eleList, clickedIdx) {
        //console.log(`[ray-refine-zh.js] engrave`);
        let $target = $(event.target);
        let taggedObj = rubies.pop();
        
        if (!taggedObj || !taggedObj.raw || typeof (taggedObj.raw) !== 'string') {
            return;
        }

        let targetIdx = -1;
        rubies.forEach((obj, index) => {
            if (obj.indices.indexOf(clickedIdx) >= 0) {
                targetIdx = index;
            }
        });
        
        let arkenStr = taggedObj.raw.replace(/<|>/g, function (match) {
            if (match === '<') {
                return '<span class="u_word_dic" data-hook="tip" data-type="arken" data-lang="zh">';
            } else if (match === '>') {
                return '</span>';
            }
        });

        let html = $.parseHTML(arkenStr);
        let targetElement = event.target.parentNode;
        let parentNode = targetElement.parentNode;
        let targetElementNew = null;

        try {
            for (let i=0; i<html.length; i++) {
                let newNode = document.importNode(html[i],true);
                if (i === targetIdx) {
                    targetElementNew = newNode;
                }
                parentNode.insertBefore(newNode,targetElement);
            }
            parentNode.removeChild(targetElement);
        } catch (err) {
            console.log(err.message + "\n툴팁 대상에 오류가 있습니다. 아래 툴팁 대상이 올바른지 확인해보시기 바랍니다. \nerror engrave zh target:" + targetElement);
        }

        //console.log(`targetIdx = ${targetIdx}`);
        if (targetIdx > -1 && getCurrentOptions().isMobile) {
            //console.log(`[raw-refine.zh.js] engrave, trigger mobile click`);
            $(targetElementNew).trigger('click');
        } else if (targetIdx > -1 && getCurrentOptions().isMobile === false) {
            //$(targetElementNew).trigger('mouseover');
        }

        return targetElementNew;
    }
};


export default markerByLang;