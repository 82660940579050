import classMap from '../config/codeClassMap.js';
import $ from 'jQuery';

export default function arken_degrade(event) {
    //rubies, event, $eleList,clickedIdx
    //console.log(`arken_degrade`);
    
    var $target = $(event.target);
    
    var sourceLang = $target.attr('data-lang');
    var grpId = $target.attr('data-grp');
    var clickedEle = null;
    if (grpId) {
        
        var $src = $(`span[data-grp='${grpId}']`);
        
        $src.each(function (index, value) {
            let parentNode = value.parentNode;
            var tarStr = `<span class="u_word_dic" data-hook="tip" data-type="arken" data-lang="${sourceLang}">${$(this).text()}</span>`;
            var html = $.parseHTML(tarStr);

            /*for (let j=0;j<html.length;j++) {
                let newNode = document.importNode(html[j],true);
                parentNode.insertBefore(newNode,value);
            }*/

            let newNode = document.importNode(html[0],true);
            parentNode.insertBefore(newNode,value);

            if (event.target.textContent === value.textContent) {
                clickedEle = newNode
            }

            parentNode.removeChild(value);
            //$(this).before(html);
            //$(this).remove();
            

            
        });
        event.target = clickedEle; // replace event target with new arken
    }
    
}