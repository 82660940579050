import $ from 'jQuery';
import {
	getCurrentOptions
} from '../config/appConfig.js';
import isHanjaZoomCase from './is-hanja-zoom-case.js';
import classMap from '../config/codeClassMap.js';
import {
	getCurrentNsc
} from "../config/appConfig";

export function setZoomLayerWidth($targetWord) {
	let $zoomLayer = $('#' + classMap['zoomLayerId']);
	let layerWidth = $zoomLayer.width();
	let clientWidth = document.documentElement.clientWidth || document.body.clientWidth; // $.innerWidth
	let targetWordLeft = $targetWord.offset().left;
	let targetWordWidth = $targetWord.width();

	if (targetWordLeft + layerWidth <= clientWidth) { // align left
		$zoomLayer.css({
			left: targetWordLeft,
			right: 'auto'
		});
	} else if (clientWidth - targetWordLeft - targetWordWidth + layerWidth <= clientWidth) { // align right
		$zoomLayer.css({
			left: 'auto',
			right: clientWidth - targetWordLeft - targetWordWidth,
		});
	} else { // right in the middle
		$zoomLayer.css({
			left: '20px',
			right: '20px'
		});
	}

}

// customize layer position
export function setCustomLayerPosition(target, $tipLayer, underWhich) {

	const currentOptions = getCurrentOptions();
	const isGreenDot = (currentOptions.layerType === "greendot");
	const pointerId = classMap["sPointerId"];
	//console.log(`[layer-position.js] setCustomLayerPosition, isGreenDot = ${isGreenDot}`);

	const offset = 4; // defined by designer
	let magic_offset = 5;
	let cLeft = 0;
	let cTop = 0;

	//한자사전 단어장 추가 버튼이 있는 경우 u_origin_word 내용이 너무 길 경우 두줄 모드로
	if (getCurrentNsc() === 'dic.tt.cck' || getCurrentNsc() === 'mdic.tt.cck') {
		let headWordElements = $('dt.u_headword');
		headWordElements.each(function(index) {
			let originHeight = $(this).height();
			//console.log(`[layer-position.js] setHanjaTipLayerPosition, u_headword = ${originHeight}`);
			if (originHeight > 40) {
				let originElement = $(this).find('.tooltipLayer_origin_word')[0];
				$(this)[0].appendChild(originElement);
			}
		});
	}

	//viewport 넓이 높이
	let clientWidth = document.documentElement.clientWidth; //viewport width
	let clientHeight = document.documentElement.clientHeight; //viewport height
	//console.log(`[layer-position.js] setPosition, viewport width = ${clientWidth},viewport height = ${clientHeight}`);

	let viewportOffset = target.getBoundingClientRect();
	let targetTopFromViewport = viewportOffset.top; // relative to viewport
	let targetLeftFromViewport = viewportOffset.left; // relative to viewport
	let targetWidth = viewportOffset.width;
	let targetHeight = viewportOffset.height;
	//console.log(`[layer-position.js] setPosition, targetTopFromViewport = ${targetTopFromViewport}, targetHeight = ${targetHeight}`);

	let targetOffsetTop = $(target).offset().top; //relative to document
	let targetOffsetLeft = $(target).offset().left;
	//console.log(`[layer-position.js] setPosition, targetOffsetTop = ${targetOffsetTop}, targetOffsetLeft = ${targetOffsetLeft}`);

	let contentArea = document.querySelector(underWhich);
	let contentAreaTop = contentArea.getBoundingClientRect().top;
	//console.log(`[layer-position.js] contentAreaTop = ${contentAreaTop}, contentArea position = ${contentArea.style.position}`);

	//툴팁 레이어 넓이, 높이
	let tooltipLayerHeight = $tipLayer.height();
	let tooltipLayerWidth = $tipLayer.width();
	//console.log(`[layer-postion.js] tooltipLayerHeight = ${tooltipLayerHeight}`);

	// document가 위로 scroll된 값
	let documentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
	//console.log(`[layer-position.js] document scrollTop = ${documentScrollTop}`);

	// 목표단어의 위 혹은 아래에 붙일것이냐
	// 화면 높이 중간선을 기준으로 단어가 중간선 아래에 위치해 있고 팝업레이어의 높이가 화면 상단을 넘치지 않는다면 위에 노출
	// 그외 상황은 모두 하위에 노출
	let isDownLayer = true;
	if ((targetTopFromViewport * 2 + targetHeight > clientHeight) && tooltipLayerHeight < targetTopFromViewport) {
		isDownLayer = false;
	}

	//cTop은 isDownLayer의 거짓참에 따라 분기된다.
	if (isDownLayer) { // 아래 붙이기
		if (contentArea.style.position === 'relative') {
			cTop = targetTopFromViewport - contentAreaTop + targetHeight + 4;
		} else {
			//relative to document
			cTop = targetTopFromViewport + documentScrollTop + targetHeight + 4;
		}
		if (isGreenDot === false) {
			cTop += 4;
		}
	} else { // 위에 붙이기
		if (contentArea.style.position === 'relative') {
			cTop = targetTopFromViewport - contentAreaTop - tooltipLayerHeight - 7;
		} else {
			//relative to document
			cTop = targetTopFromViewport + documentScrollTop - tooltipLayerHeight - 7;
		}
		if (isGreenDot === false) {
			cTop -= 2;
		}
	}
	//console.log(`[layer-position.js] cTop = ${cTop}`);

	if (currentOptions.isMobile || currentOptions.prCode === 'mobile.news') {
		// mobile
		//console.log(`set position, is mobile`);
		if (isGreenDot) {
			cLeft = 15;
		}

		//korean/////////////
		// http://bts1.navercorp.com/nhnbts/browse/DICNGDSUS-28811
		// 정확한 수정 방법은 아닙니다. 다시 이슈가 생기면 정확한 수정 방법으로 잘 부탁드립니다.
		// var pointerId = classMap["sPointerId"];
		if ($('#' + pointerId).get(0)) {
			// 화살기호의 x 좌표 위치
			let arrowLeftPosition = targetOffsetLeft - magic_offset + targetWidth / 2;
			if (targetWidth < 100) {
				_setPointerPosition(pointerId, arrowLeftPosition, isDownLayer);
			} else {
				$('#' + pointerId).hide();
			}
			_setPointerDirection(classMap["tipLayerId"], isDownLayer);
		}
	} else {
		// pc or tablet
		//console.log(`set position, is pc`);
		if (targetLeftFromViewport + tooltipLayerWidth > clientWidth) {
			if (isGreenDot) {
				cLeft = 'auto';
			} else {
				//cLeft = clientWidth - tooltipLayerWidth;
				cLeft = 'auto';
				$tipLayer.addClass('right');
			}
		} else {
			cLeft = targetLeftFromViewport;
		}
	}

	$tipLayer.offset({
		top: cTop,
		left: cLeft
	});
	//$tipLayer.css({top: cTop, left: cLeft});

}

// line use greendot
export function setLineLayerPosition(target, $tipLayer) {

	const currentOptions = getCurrentOptions();
	const isGreenDot = (currentOptions.layerType === "greendot");
	const pointerId = classMap["sPointerId"];
	//console.log(`[layer-position.js] setLineLayerPosition, isGreenDot = ${isGreenDot}`);

	const offset = 4; // defined by designer
	let magic_offset = 5;
	let cLeft = 0;
	let cTop = 0;

	//viewport 넓이 높이
	let clientWidth = document.documentElement.clientWidth; //viewport width
	let clientHeight = document.documentElement.clientHeight; //viewport height
	//console.log(`[layer-position.js] setPosition, viewport width = ${clientWidth},viewport height = ${clientHeight}`);

	let viewportOffset = target.getBoundingClientRect();
	let targetTopFromViewport = viewportOffset.top; // relative to viewport
	let targetLeftFromViewport = viewportOffset.left; // relative to viewport
	let targetWidth = viewportOffset.width;
	let targetHeight = viewportOffset.height;
	//console.log(`[layer-position.js] setPosition, targetTopFromViewport = ${targetTopFromViewport}, targetHeight = ${targetHeight}`);

	let targetOffsetTop = $(target).offset().top; //relative to document
	let targetOffsetLeft = $(target).offset().left;
	//console.log(`[layer-position.js] setPosition, targetOffsetTop = ${targetOffsetTop}, targetOffsetLeft = ${targetOffsetLeft}`);

	let contentArea = document.querySelector("#wrap #container #content");
	let contentAreaTop = contentArea.getBoundingClientRect().top;
	//console.log(`[layer-position.js] contentAreaTop = ${contentAreaTop}, contentArea position = ${contentArea.style.position}`);

	//툴팁 레이어 넓이, 높이
	let tooltipLayerHeight = $tipLayer.height();
	let tooltipLayerWidth = $tipLayer.width();
	//console.log(`[layer-postion.js] tooltipLayerHeight = ${tooltipLayerHeight}`);

	// document가 위로 scroll된 값
	let documentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
	//console.log(`[layer-position.js] document scrollTop = ${documentScrollTop}`);

	// 목표단어의 위 혹은 아래에 붙일것이냐
	// 화면 높이 중간선을 기준으로 단어가 중간선 아래에 위치해 있고 팝업레이어의 높이가 화면 상단을 넘치지 않는다면 위에 노출
	// 그외 상황은 모두 하위에 노출
	let isDownLayer = true;
	if ((targetTopFromViewport * 2 + targetHeight > clientHeight) && tooltipLayerHeight < targetTopFromViewport) {
		isDownLayer = false;
	}

	//cTop은 isDownLayer의 거짓참에 따라 분기된다.
	if (isDownLayer) { // 아래 붙이기
		if (contentArea.style.position === 'relative') {
			cTop = targetTopFromViewport - contentAreaTop + targetHeight + 4;
		} else {
			//relative to document
			cTop = targetTopFromViewport + documentScrollTop + targetHeight + 4;
		}
		if (isGreenDot === false) {
			cTop += 4;
		}
	} else { // 위에 붙이기
		if (contentArea.style.position === 'relative') {
			cTop = targetTopFromViewport - contentAreaTop - tooltipLayerHeight - 7;
		} else {
			//relative to document
			cTop = targetTopFromViewport + documentScrollTop - tooltipLayerHeight - 7;
		}
		if (isGreenDot === false) {
			cTop -= 2;
		}
	}
	//console.log(`[layer-position.js] cTop = ${cTop}`);

	if (currentOptions.isMobile || currentOptions.prCode === 'mobile.news') {
		// mobile
		//console.log(`set position, is mobile`);
		if (isGreenDot) {
			cLeft = 15;
		}

		//korean/////////////
		// http://bts1.navercorp.com/nhnbts/browse/DICNGDSUS-28811
		// 정확한 수정 방법은 아닙니다. 다시 이슈가 생기면 정확한 수정 방법으로 잘 부탁드립니다.
		// var pointerId = classMap["sPointerId"];
		if ($('#' + pointerId).get(0)) {
			// 화살기호의 x 좌표 위치
			let arrowLeftPosition = targetOffsetLeft - magic_offset + targetWidth / 2;
			if (targetWidth < 100) {
				_setPointerPosition(pointerId, arrowLeftPosition, isDownLayer);
			} else {
				$('#' + pointerId).hide();
			}
			_setPointerDirection(classMap["tipLayerId"], isDownLayer);
		}
	} else {
		// pc or tablet
		//console.log(`set position, is pc`);
		if (targetLeftFromViewport + tooltipLayerWidth > clientWidth) {
			if (isGreenDot) {
				cLeft = 'auto';
			} else {
				//cLeft = clientWidth - tooltipLayerWidth;
				cLeft = 'auto';
				$tipLayer.addClass('right');
			}
		} else {
			cLeft = targetLeftFromViewport;
		}
	}

	$tipLayer.offset({
		top: cTop,
		left: cLeft
	});
	//$tipLayer.css({top: cTop, left: cLeft});

}

export function setHanjaZoomLayerPosition(target) {
	let $hanja = $(target);
	let $hanjaBlock = $(target.parentNode);
	let $zoomLayer = $('#' + classMap['zoomLayerId']);
	const currentOptions = getCurrentOptions();

	let offset = 2; // defined by designer
	let cLeft = 0;
	let cRight = 0;
	let cTop = 0;

	let zoomLayerWidth = $zoomLayer.width();
	let zoomLayerHeight = $zoomLayer.height();

	let hanjaLeft = $hanja.offset().left;
	let hanjaTop = $hanja.offset().top;
	let hanjaHeight = $hanja.height();
	let hanjaBlockLeft = $hanjaBlock.offset().left;
	let hanjaBlockTop = $hanjaBlock.offset().top;
	let hanjaBlockHeight = $hanjaBlock.height();
	//console.log(`[layer-position.js] setHanjaZoomLayerPosition, hanjaHeight = ${hanjaHeight}, hanjaBlockHeight = ${hanjaBlockHeight}`);

	//viewport width and height
	let clientWidth = document.documentElement.clientWidth || document.body.clientWidth; // $.innerWidth
	let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

	let viewportOffset = target.parentNode.getBoundingClientRect();
	let targetTopFromViewport = viewportOffset.top; // relative to viewport
	let targetLeftFromViewport = viewportOffset.left; // relative to viewport
	let targetWidth = viewportOffset.width;
	let targetHeight = viewportOffset.height;
	//console.log(`[layer-position.js] setHanjaZoomLayerPosition, targetTopFromViewport = ${targetTopFromViewport}, targetHeight = ${targetHeight}`);

	let targetOffsetTop = $(target).offset().top; //relative to document
	let targetOffsetLeft = $(target).offset().left;
	//console.log(`[layer-position.js] setPosition, targetOffsetTop = ${targetOffsetTop}, targetOffsetLeft = ${targetOffsetLeft}`);

	let contentArea = null;
	let contentAreaTop = null;
	if (currentOptions.layerPosition) {
		contentArea = document.querySelector(currentOptions.layerPosition);
		contentAreaTop = contentArea.getBoundingClientRect().top;
	}
	//console.log(`[layer-position.js] contentAreaTop = ${contentAreaTop}, contentArea position = ${contentArea.style.position}`);

	//툴팁 레이어 넓이, 높이
	let tipLayerWidth = $('#' + classMap['tipLayerId']).width();
	let tipLayerHeight = $('#' + classMap['tipLayerId']).height();

	let documentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
	//console.log(`[layer-postion.js] document scrollTop = ${documentScrollTop}`);

	/*let isDownLayer = true;
	if ((targetTopFromViewport*2 + targetHeight > clientHeight) && tooltipLayerHeight < targetTopFromViewport) {
	    isDownLayer = false;
	}*/
	// 1 : 툴팁 레이어를 아래에 노출할 경우 잘리지 않는 케이스
	// 2 : 툴팁 레이어를 위에 노출할 경우 잘리는 케이스
	// 목표단어의 위 혹은 아래에 붙일것이냐
	let isMultiLine = false;
	if (hanjaHeight < hanjaBlockHeight * 2 / 3) {
		// 줄바꿈처리되어 두줄로 나올 경우
		//console.log(`두줄로 표시`);
		isMultiLine = true;
		if (currentOptions.layerPosition) {
			cTop = _setZoomLayerTop({
				targetTopFromViewport,
				clientHeight,
				contentArea,
				contentAreaTop,
				hanjaBlockHeight,
				zoomLayerHeight,
				documentScrollTop,
				isMultiLine
			})
		} else {
			if ((hanjaTop - documentScrollTop) < clientHeight * 2 / 3) {
				// 아래 붙이기
				cTop = hanjaBlockTop + hanjaBlockHeight + offset;
				currentOptions.isHanjaZoomLayerUnderWord = true;
			} else {
				// 위에 붙이기
				cTop = hanjaBlockTop - zoomLayerHeight - 6 - offset;
				currentOptions.isHanjaZoomLayerUnderWord = false;
			}
		}

		if (currentOptions.isMobile && currentOptions.layerType !== "greendot" && $hanjaBlock.children().length > 7) {
			cLeft = 20;
		} else if (hanjaLeft + zoomLayerWidth > clientWidth) {
			cLeft = 'auto';
		} else {
			cLeft = hanjaBlockLeft;
		}
	} else {
		// 한줄에 나오는 경우
		//console.log(`한줄로 표시`);
		if (currentOptions.layerPosition) {
			cTop = _setZoomLayerTop({
				targetTopFromViewport,
				clientHeight,
				contentArea,
				contentAreaTop,
				hanjaBlockHeight,
				zoomLayerHeight,
				documentScrollTop,
				isMultiLine
			});
		} else {
			if ((hanjaBlockTop - documentScrollTop) < clientHeight * 2 / 3) {
				// 아래 붙이기
				cTop = hanjaBlockTop + hanjaBlockHeight + offset;
				currentOptions.isHanjaZoomLayerUnderWord = true;
			} else {
				// 위에 붙이기
				cTop = hanjaBlockTop - zoomLayerHeight - 6 - offset;
				currentOptions.isHanjaZoomLayerUnderWord = false;
			}
		}

		if (currentOptions.isMobile && currentOptions.layerType !== "greendot" && $hanjaBlock.children().length > 7) {
			cLeft = 20;
		} else if (hanjaBlockLeft + zoomLayerWidth + 20 > clientWidth) {
			cLeft = 'auto';
			cRight = 20;
		} else {
			cLeft = hanjaBlockLeft;
		}

	}
	//console.log(`[layer-position.js] setHanjaZoomLayerPosition, cTop = ${cTop}, cLeft = ${cLeft}, cRight = ${cRight}`);

	if (cRight > 0) {
		$zoomLayer.css('left', 'auto');
		$zoomLayer.css('right', cRight);
	}

	if (currentOptions.layerPosition) {
		$zoomLayer.offset({
			top: cTop,
			left: cLeft
		});
	} else {
		$zoomLayer.css({
			top: cTop,
			left: cLeft
		});
	}
}

function _setZoomLayerTop(options) {
	const currentOptions = getCurrentOptions();
	let cTop = 0;
	let {
		targetTopFromViewport,
		clientHeight,
		contentArea,
		contentAreaTop,
		hanjaBlockHeight,
		zoomLayerHeight,
		documentScrollTop,
		isMultiLine
	} = options;
	//console.log(`[layer-position.js] _setZoomLayerTop, targetTopFromViewport = ${targetTopFromViewport}, clientHeight = ${clientHeight}`);
	if (targetTopFromViewport < clientHeight * 2 / 3) {
		// 아래 붙이기
		if (contentArea.style.position === 'relative') {
			cTop = targetTopFromViewport - contentAreaTop + hanjaBlockHeight + 2;
		} else {
			//relative to document
			cTop = targetTopFromViewport + documentScrollTop + hanjaBlockHeight + 2;
		}
		if (currentOptions.layerType !== 'greendot') {
			cTop += 4;
		}
		currentOptions.isHanjaZoomLayerUnderWord = true;
	} else {
		// 위에 붙이기
		if (contentArea.style.position === 'relative') {
			cTop = targetTopFromViewport - contentAreaTop - zoomLayerHeight - 8;
		} else {
			//relative to document
			cTop = targetTopFromViewport + documentScrollTop - zoomLayerHeight - 8;
		}
		if (currentOptions.layerType !== 'greendot') {
			cTop -= 2;
		}
		currentOptions.isHanjaZoomLayerUnderWord = false;
	}
	return cTop;
}

export function setHanjaTipLayerPosition(targetWord, $tipLayer) {
	const currentOptions = getCurrentOptions();
	//console.log(`[layer-position.js] setHanjaTipLayerPosition`);

	let $targetWord = $(targetWord);
	let offset = 2; // defined by designer
	let pointerHeight = 0;
	if (currentOptions.layerType !== "greendot") {
		pointerHeight = 6;
	}
	let cLeft = 0;
	let cRight = 0;
	let cTop = 0;

	let $zoomLayer = $('#' + classMap['zoomLayerId']);
	let zoomLayerTop = $zoomLayer.offset().top;
	let zoomLayerLeft = $zoomLayer.offset().left;
	let zoomLayerHeight = $zoomLayer.height();

	let targetWordTop = $targetWord.offset().top; //relative to document
	let targetWordLeft = $targetWord.offset().left;
	let targetWordWidth = $targetWord.width();

	//viewport width and height
	let clientWidth = document.documentElement.clientWidth || document.body.clientWidth; // $.innerWidth
	let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

	//한자사전 단어장 추가 버튼이 있는 경우 u_origin_word 내용이 너무 길 경우 두줄 모드로
	if (getCurrentNsc() === 'dic.tt.cck' || getCurrentNsc() === 'mdic.tt.cck') {
		let headWordElements = $('dt.u_headword');
		headWordElements.each(function(index) {
			let originHeight = $(this).height();
			//console.log(`[layer-position.js] setHanjaTipLayerPosition, u_headword = ${originHeight}`);
			if (originHeight > 40) {
				let originElement = $(this).find('.tooltipLayer_origin_word')[0];
				$(this)[0].appendChild(originElement);
			}
		});
	}

	//////////////////////////////// custom layer position
	let viewportOffset = $zoomLayer[0].getBoundingClientRect();
	let zoomLayerTopFromViewport = viewportOffset.top; // relative to viewport
	//let hanjaBlockLeftFromViewport = viewportOffset.left; // relative to viewport
	//let hanjaBlockWidth = viewportOffset.width;
	//let hanjaBlockHeight = viewportOffset.height;
	//console.log(`[layer-position.js] setPosition, targetTopFromViewport = ${targetTopFromViewport}`);

	//let targetOffsetTop = $(targetWord).offset().top;  //relative to document
	//let targetOffsetLeft = $(targetWord).offset().left;
	//console.log(`[layer-position.js] setPosition, targetOffsetTop = ${targetOffsetTop}, targetOffsetLeft = ${targetOffsetLeft}`);

	let contentArea = null;
	let contentAreaTop = null;
	if (currentOptions.layerPosition) {
		contentArea = document.querySelector(currentOptions.layerPosition);
		contentAreaTop = contentArea.getBoundingClientRect().top;
	}
	//console.log(`[layer-position.js] contentAreaTop = ${contentAreaTop}, contentArea position = ${contentArea.style.position}`);
	//////////////////////////////// custom layer position

	//툴팁 레이어 넓이, 높이
	let tipLayerWidth = $('#' + classMap['tipLayerId']).width();
	let tipLayerHeight = $('#' + classMap['tipLayerId']).height();
	//console.log(`[layer-postion.js] tooltipLayerHeight = ${tooltipLayerHeight}`);

	let documentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
	//console.log(`[layer-postion.js] document scrollTop = ${documentScrollTop}`);

	// 1 : 툴팁 레이어를 아래에 노출할 경우 잘리지 않는 케이스
	// 2 : 툴팁 레이어를 위에 노출할 경우 잘리는 케이스
	// 목표단어의 위 혹은 아래에 붙일것이냐
	if (currentOptions.isHanjaZoomLayerUnderWord) {
		// 아래 붙이기
		if (currentOptions.layerPosition) {
			if (contentArea.style.position === 'relative') {
				//console.log(`아래에 붙이기 relative to special element`);
				cTop = zoomLayerTopFromViewport - contentAreaTop + zoomLayerHeight + $zoomLayer.outerHeight() - $zoomLayer.height() + 2;
			} else {
				//relative to document
				//console.log(`아래에 붙이기 relative to document`);
				cTop = zoomLayerTopFromViewport + documentScrollTop + zoomLayerHeight + $zoomLayer.outerHeight() - $zoomLayer.height() + 2;
			}
			if (currentOptions.layerType !== 'greendot') {
				cTop += 4;
			}
		} else {
			cTop = zoomLayerTop + zoomLayerHeight + 6 + offset;
		}
	} else {
		// 위에 붙이기
		if (currentOptions.layerPosition) {
			if (contentArea.style.position === 'relative') {
				//console.log(`위 붙이기 relative to special element`);
				cTop = zoomLayerTopFromViewport - contentAreaTop - tipLayerHeight - 4;
			} else {
				//relative to document
				//console.log(`위에 붙이기 relative document`);
				cTop = zoomLayerTopFromViewport + documentScrollTop - tipLayerHeight - 4;
			}
			if (currentOptions.layerType !== 'greendot') {
				cTop -= 2;
			}
		} else {
			cTop = zoomLayerTop - tipLayerHeight - 2 - offset - pointerHeight;
		}
	}

	if (currentOptions.isMobile) {
		if (currentOptions.layerType === "greendot") {
			cLeft = 15;
		}
	} else {
		// pc or tablet
		if (zoomLayerLeft + tipLayerWidth + 20 > clientWidth) {
			cLeft = 'auto';
			cRight = 20;
		} else {
			cLeft = $zoomLayer.offset().left;
		}
	}

	if (currentOptions.isMobile && $('#' + classMap["sPointerId"]).get(0)) {
		// 화살기호의 x 좌표 위치
		let arrowLeftPosition = targetWordLeft + targetWordWidth / 2;
		if (targetWordWidth < 100) {
			_setPointerPosition(arrowLeftPosition, currentOptions.isHanjaZoomLayerUnderWord);
		} else {
			$('#' + classMap["sPointerId"]).hide();
		}
		_setPointerDirection(classMap["tipLayerId"], currentOptions.isHanjaZoomLayerUnderWord);
	}


	//console.log(`[layer-position.js] setHanjaTipLayerPosition(), cTop = ${cTop}, cLeft = ${cLeft}, tipLayerHeight = ${tipLayerHeight}`);
	if (currentOptions.layerPosition) {
		$tipLayer.offset({
			top: cTop,
			left: cLeft
		});
	} else {
		$tipLayer.css({
			top: cTop,
			left: cLeft
		});
	}

	if (cRight > 0) {
		$tipLayer.css('left', 'auto');
		$tipLayer.css('right', cRight);
	}
}

export function setGeneralLayerPosition(targetWord, $tipLayer, isZoomClicked) {
	const currentOptions = getCurrentOptions();
	const isGreenDot = (currentOptions.layerType === "greendot");
	const pointerId = classMap["sPointerId"];
	//console.log(`[layer-position.js] setGeneralLayerPosition, isZoomClicked = ${isZoomClicked}, isGreenDot = ${isGreenDot}`);

	let $targetWord = $(targetWord);
	let offset = 2; // defined by designer
	let pointerHeight = 6; //  defined by markup(pointer html)
	let cLeft = 0;
	let cTop = 0;
	let magic_offset = 5;
	let magic_offset_top = 5;
	if (isHanjaZoomCase()) {
		magic_offset = 0;
		magic_offset_top = 10;
	}

	//한자사전 단어장 추가 버튼이 있는 경우 u_origin_word 내용이 너무 길 경우 두줄 모드로
	if (getCurrentNsc() === 'dic.tt.cck' || getCurrentNsc() === 'mdic.tt.cck') {
		let headWordElements = $('dt.u_headword');
		headWordElements.each(function(index) {
			let originHeight = $(this).height();
			//console.log(`[layer-position.js] setHanjaTipLayerPosition, u_headword = ${originHeight}`);
			if (originHeight > 40) {
				let originElement = $(this).find('.tooltipLayer_origin_word')[0];
				$(this)[0].appendChild(originElement);
			}
		});
	}

	let targetWordTop = $targetWord.offset().top; //relative to document
	let targetWordLeft = $targetWord.offset().left;

	// 목표단어의 넓이, 높이
	let targetWordHeight = $targetWord.height();
	let targetWordWidth = $targetWord.width();

	//document의  inner 넓이 높이
	let clientWidth = document.documentElement.clientWidth || document.body.clientWidth; // $.innerWidth
	let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

	//툴팁 레이어 넓이, 높이
	let tooltipLayerHeight = $tipLayer.height();
	let tooltipLayerWidth = $tipLayer.width();
	//console.log(`[layer-position.js] tooltipLayerHeight = ${tooltipLayerHeight}`);

	// document가 위로 scroll된 값

	/*document.body.onscroll = function(event) {
	    let rect = contentArea.getBoundingClientRect();
	    console.log(`contentArea scrolltop = ${contentArea.offsetTop}`);
	};*/
	let documentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
	//console.log(`[layer-postion.js] document scrollTop = ${documentScrollTop}`);


	//1 || 2
	// 1: 툴팁 레이어를 아래에 노출할 경우 잘리지 않는 케이스
	//2 : 툴팁 레이어를 위에 노출할 경우 잘리는 케이스
	let wordTopViewport = targetWordTop - documentScrollTop;
	//console.log(`[layer-position.js] setGeneralLayerPosition, wordTopViewport = ${wordTopViewport}, tooltipLayerHeight = ${tooltipLayerHeight}, clientHeight = ${clientHeight}`);
	let isDownLayer = (wordTopViewport + tooltipLayerHeight <= clientHeight) || (wordTopViewport < tooltipLayerHeight);
	//console.log(`[layer-postion.js] isDownLayer = ${isDownLayer}`);

	// if (this.option("layerPositionFix")) {
	// 	isDownLayer = true;  //  타 서비스의 레이어와 겹칠 경우  아래에 고정해서 노출되게 하는 옵션
	// }
	// 목표단어의 위 혹은 아래에 붙일것이냐
	//cTop은 isDownLayer의 거짓참에 따라 분기된다.
	if (isDownLayer) { // 아래 붙이기
		cTop = targetWordTop + targetWordHeight + offset; // magic_offset is hard coding
		if (isZoomClicked) { //한자확대 레이어 아래에 툴팁레이어를 붙일 경우
			cTop += 6;
		}
	} else { // 위에 붙이기
		if (isGreenDot) { // greendot
			pointerHeight = 0;
		}
		//cTop = targetWordTop - tooltipLayerHeight - pointerHeight - offset; // 6 == pointer height
		cTop = targetWordTop - tooltipLayerHeight - 2 - pointerHeight - offset;
	}
	//console.log(`[layer-postion.js] cTop = ${cTop}`);

	//cLeft  // 가로 넓이를 넘쳐난다.// 모바일에서 주로 발생
	/*if (targetWordLeft + tooltipLayerWidth > clientWidth) {
	    if (isGreenDot) {
	        cLeft = 'auto';
	    } else {
	        cLeft = clientWidth - tooltipLayerWidth;
	        $tipLayer.addClass('right');
	    }
	} else { // pc or tablet
	    cLeft = targetWordLeft;
	}*/

	if (currentOptions.isMobile || currentOptions.prCode === 'mobile.news') {
		// mobile
		//console.log(`set position, is mobile`);
		if (isGreenDot) {
			cLeft = 15;
		}

		//korean/////////////
		// http://bts1.navercorp.com/nhnbts/browse/DICNGDSUS-28811
		// 정확한 수정 방법은 아닙니다. 다시 이슈가 생기면 정확한 수정 방법으로 잘 부탁드립니다.
		// var pointerId = classMap["sPointerId"];
		if ($('#' + pointerId).get(0)) {
			// 화살기호의 x 좌표 위치
			let arrowLeftPosition = targetWordLeft - magic_offset + targetWordWidth / 2;
			if (targetWordWidth < 100) {
				_setPointerPosition(arrowLeftPosition, isDownLayer);
			} else {
				$('#' + pointerId).hide();
			}
			_setPointerDirection(classMap["tipLayerId"], isDownLayer);
		}

	} else {
		// pc or tablet
		//console.log(`set position, is pc`);
		if (targetWordLeft + tooltipLayerWidth > clientWidth) {
			if (isGreenDot) {
				cLeft = 'auto';
			} else {
				//cLeft = clientWidth - tooltipLayerWidth;
				cLeft = 'auto';
				$tipLayer.addClass('right');
			}
		} else {
			cLeft = targetWordLeft;
		}
	}

	// if (currentOptions.useScrollLayer) {
	// 	if (window.innerHeight > tooltipLayerHeight) {
	// 		cTop = window.innerHeight / 2 + ((window.innerHeight - tooltipLayerHeight) / 4);
	// 	} else {
	// 		cTop = window.innerHeight / 2;
	// 	}
	// }
	$tipLayer.css({
		top: cTop,
		left: cLeft
	});

	/*var isTooltipLayer = $tipLayer.hasClass('u_helpdict_module');
    console.log(`isTooltipLayer is ${isTooltipLayer}`);
	var layerOffsetLeft = $tipLayer.offset().left;
	console.log(`layerOffsetLeft = ${layerOffsetLeft}`);
    if (isTooltipLayer && layerOffsetLeft >= 185) {
        $tipLayer.addClass('right');
    }*/
}

function _setPointerDirection(layerId, isDownLayer) {

	var layer = $('#' + layerId);

	if (layer.get(0)) {

		var upClass = classMap["uparrowClass"];
		var downClass = classMap["downarrowClass"];
		if (isDownLayer) {

			layer.removeClass(downClass);
			layer.addClass(upClass);
		} else {

			layer.removeClass(upClass);
			layer.addClass(downClass);
		}
	}

}

// 모바일 툴팁 레이어 화살표 포인터 위치 계산 함수
function _setPointerPosition(left, isDownLayer) {
	// var arrPointer = dttjindo.$Element(pointerId);
	var arrPointer = $('#' + classMap["sPointerId"]);
	if (arrPointer.get(0)) {
		arrPointer.css('display', 'block');
		arrPointer.css('left', left);
		if (isDownLayer) {
			arrPointer.removeClass('down');
		} else {
			arrPointer.addClass('down');
		}
	}

}
