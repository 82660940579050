import i18nText from './i18n-message.js';


export default function (userCode, count) {
    //console.log(`[otherMeanings.js] userCode = ${userCode}, count = ${count}`);
    if (!i18nText['otherMeanings'][userCode]) {
        return i18nText['otherMeanings'].en(count);
    }
    return i18nText['otherMeanings'][userCode](count);
}
