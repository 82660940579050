

export default function isMobile(){

	const evidence = navigator.userAgent || navigator.vendor || window.opera;
	//console.log(`[detectMobile.js] evidence = ${evidence}`);
	//window.alert(`navigator.userAgent = ${navigator.userAgent}`);
	//window.alert(`navigator.vendor = ${navigator.vendor}`);
	//window.alert(`window.opera= ${window.opera}`);
	//window.alert(`evidence = ${evidence}`);
	if (/Mobile|iPhone|iPad|iPod|Tablet|Opera Mini/.test(evidence)) {
		//console.log(`[detectMobile.js] isMobile = true`);
		//window.alert(`isMobile = true`);
		return true;
	}
	//window.alert(`isMobile = false`);
	//console.log(`[detectMobile.js] isMobile = false`);
	return false;
}