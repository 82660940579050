import $ from 'jQuery';
import isMobile from '../Util/detectMobile';
import supportPairBand from './pairBand.json';
import userCodeBand from './userCodeBand.json';

let NNBCookie = '';
let serviceDomain = '';
let serviceURL = '';
let currentOptions = {};
let currentNsc = '';
let dataApi = '';
let isMobileDevice = isMobile();
if(URL_FROM_WEBPACK_CONFIG){
	dataApi = URL_FROM_WEBPACK_CONFIG;
}

let defaultOptions = {
	prCode: 'dict',
	ignoreElementList: [],
	default_skipTagSelector: "script, style, a, button, br, rp, rt, [data-type='ore'], span.u_word_dic", // 디폴트로 skip 해야 할 태그들
	lang: {
		// "en": {
		// 	minWordLength: 2
		// }
	},
	isMobile:isMobileDevice,
	userCode: "ko",
	layerTarget:isMobileDevice?1:2,
	dataApi:dataApi
};
if (document.createElement('audio').canPlayType) {
	defaultOptions.isPlayAudio = true;
}
//console.log(`defaultOptions.isMobile = ${defaultOptions.isMobile}`);

function _checkCodeValidity(currentOptions) {
	//console.log(`[appConfig.js] _checkCodeValidity, userCode = ${currentOptions.userCode}`);
	let allowedCode = userCodeBand[currentOptions.userCode];

	// invalid userCode
	if (!allowedCode) {
		//throw new Error('Invalid userCode, please confirm. you can call "tip.PrintUserCode()" method and look into it');
		console.log('Invalid userCode, please confirm. you can call "tip.PrintUserCode()" method and look into it');
	}

	if (!currentOptions.lang || $.isEmptyObject(currentOptions.lang)) {
		//throw new Error('invalid lang option, please check');
		console.log('invalid lang option, please check');
	}

	let lang = {};
	$.each(currentOptions.lang, function (key, value) {
		if (!supportPairBand[key]) {
			//throw new Error('unsupported source language, please check');
			console.log('unsupported source language, please check');
		} else if (!value['target']){
			//throw new Error('no "target" property in "lang" option, please check');
			console.log('no "target" property in "lang" option, please check');
		} else if (!supportPairBand[key][value["target"]]){
			//throw new Error('unsupported target language, please check');
			console.log('unsupported target language, please check');
		} else {
			lang[key] = value;
		}
	});
	
	// 사용자가 아무것도 입력하지 않았거나 정확한 코드를 입력 하지 않았다면 예외처리
	/* if ($.isEmptyObject(lang)) {
		throw new Error('invalid lang option, please check');
	} */
	currentOptions.lang = lang;
}

export function initConfig(contentSelector, userConfig, callbackFunc) {
	let option = userConfig || {};

	// 사용자 옵션으로 디폴트 옵션에 덮어쓴다
	currentOptions = $.extend({}, defaultOptions, option);
	//check user code and target code mapping validity
	_checkCodeValidity(currentOptions);

	//툴팁모듈을 적용할 태그 selector
	currentOptions.contentSelector = contentSelector;
	setIgnoreElementList();

	if (callbackFunc) {
		currentOptions.callbackFunc = callbackFunc;
	}

	if (currentOptions.layerType === 'greendot') {
		currentOptions['isMobile'] = isMobileDevice;
	} else {
		if (!currentOptions.hasOwnProperty('isMobile')) {
			currentOptions['isMobile'] = isMobileDevice;
		}
	}
    //console.log(`[appConfig.js] initConfig`);
    //console.dir(currentOptions);
}

export function mergeOptions(option) {
	currentOptions = $.extend(currentOptions, option);
    //console.log(`[appConfig.js] mergeOptions`);
    //console.dir(currentOptions);
}

export function getCurrentOptions() {
	return currentOptions;
}

export function setCallbackFunction(callback) {
	currentOptions.callbackFunc = callback;
}

export function getCallbackFunction() {
	return currentOptions.callbackFunc;
}

export function setIgnoreElementList() {
	currentOptions.ignoreElementList = $.merge($(currentOptions.contentSelector).find(currentOptions.default_skipTagSelector).get(), $(currentOptions.contentSelector).find(currentOptions.skipTagSelector).get());
}


export function isIgnorableElement(domNode) {
	//console.log(`[appConfig.js] isIgnorableElement`);
	if (domNode) {
		if(
			domNode.tagName === 'SCRIPT' ||
			domNode.tagName === 'STYLE' ||
			domNode.tagName === 'A' ||
			domNode.tagName === 'BUTTON' ||
			domNode.tagName === 'BR' ||
			domNode.tagName === 'HR' ||
			domNode.tagName === 'RP' ||
			domNode.tagName === 'RT' ||
			domNode.getAttribute('data-type') === 'ore'

		){
			//console.log(`[appConfig.js] isIgnorableElement, default skip, domNode.tagName = ${domNode.tagName}, domNode.outerHTML = ${domNode.outerHTML}`);
			return true;
		} else {
			return ($.inArray(domNode, currentOptions.ignoreElementList) > -1) ? true : false;
		}
	} else {
		return false;
	}
}

export function getLangList() {

	let langList = [];
	let isEn = false;

	//중요함: 영어를 제일 먼저 마킹해야 하므로  loop순서에서 영어가 맨 앞에 와야한다.
	$.each(currentOptions.lang, function (key, value) {

		if (key !== 'en') {
			langList.push(key);
		} else {
			isEn = true;
		}
	});
	if (isEn) {
		langList.unshift('en');
	}
	//console.log(`[appConfig.js] langList = ${langList}`);
	return langList;
}

export function setCurrentNsc(nsc){
	currentNsc = nsc;
}
export function getCurrentNsc(){
	return currentNsc;
}

export function getCurrentNclickDomain(){
	if (currentOptions.prCode === 'linedict') {
		return NCLICK_DOMAIN_LINE;
	} else {
		return NCLICK_DOMAIN;
	}
}

export function getContentSelector() {
	return currentOptions.contentSelector;
}

export function setContentSelector(contentSelector) {
	currentOptions.contentSelector = contentSelector;
}

export function getNNBCookie() {
	return NNBCookie;
}

export function setNNBCookie(cookie) {
	NNBCookie = cookie;
}

export function getServiceURL() {
	return serviceURL;
}

export function setServiceURL(url) {
	serviceURL = url;
}

export function getServiceDomain() {
	return serviceDomain;
}

export function setServiceDomain(domain) {
	serviceDomain = domain;
}