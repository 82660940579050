import $ from 'jQuery';
import { getCurrentOptions } from './config/appConfig.js';

export default function Marker(rawRefiners) {
	var self = this;
	this._rawRefiners = rawRefiners;

	this.mark = function (textContent,contentType,contentIndex) {
		//console.log(`[marker.js] mark, textContent = ${textContent}`);
		let isMatched = false;

		let reformedContent = textContent;
		// 모든 지정 언어에 대하여
		for(var i=0;i<self._rawRefiners.length; i++){
			let rawRefiner = self._rawRefiners[i];
			if(!rawRefiner) return;
			let result;
			if (contentType === "ruby") {
				if (rawRefiner.sourceLang === "ja") {
					result = rawRefiner.mark(reformedContent,contentType,contentIndex);
				} else {
					continue;
				}
			} else {
				result = rawRefiner.mark(reformedContent,contentType);
			}

			isMatched = isMatched || result.isMatched;
			if (isMatched === true) {
				reformedContent = result.textContent;
				//break;
			}
		}

		return {reformedContent,isMatched};
	};

	this.mark_legacy = function (shtml) {

		shtml = self._hideIgnoreItemList_legacy(shtml);
		// 모든 지정 언어에 대하여
		$.each(self._rawRefiners, function (index, rawRefiner) {
			if (!rawRefiner) return;
			shtml = rawRefiner.mark_legacy(shtml);
		});

		shtml = self._restoreIgnoreItemList_legacy(shtml);
		return shtml;
	};

	this.ignoreTargetList = [];
	this.ignoreItemList = null;
	this._hideIgnoreItemList_legacy= function (sHtml){
		
		var self  = this;
		self.ignoreItemList = getCurrentOptions().legacy.ignoreItemsRegx;

		if (self.ignoreItemList) {
			
				var innerIndex = 0;
				var regx = self.ignoreItemList;

				var oIgnoredPattern = function(match) {

					//console.log(`hit ${innerIndex}----------`);
					// console.log($1);
					innerIndex++;
					self.ignoreTargetList[self.ignoreTargetList.length] = match;
					//console.log("ignore target[" + innerIndex + "]: " + $1);
					return "!@#" + innerIndex + "#@!";
				};

				sHtml = sHtml.replace(regx, oIgnoredPattern);
		}

		return sHtml;

	};
	this._restoreIgnoreItemList_legacy = function(sHtml){

		var self = this;
		if (self.ignoreItemList||0) {


			var repalcePattern = function(match,p1) {

				let index = Number(p1);

				return self.ignoreTargetList[index-1];
			};
			// sHtml = sHtml.replace("!@#" + (index + 1) + "#@!", repalcePattern);
			sHtml = sHtml.replace(/!@#([0-9]+?)#@!/g, repalcePattern);
		}

		return sHtml;
	};
};

