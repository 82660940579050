
import en_refiner from  '../dynamicload/raw-refine-en.js';
import ko_refiner  from '../dynamicload/raw-refine-ko.js';
import hj_refiner  from  '../dynamicload/raw-refine-hj.js';
import zh_refiner  from  '../dynamicload/raw-refine-zh.js';
import ja_refiner  from  '../dynamicload/raw-refine-ja.js';

var refinerByLang = {
	"ko":ko_refiner,
	"hj":hj_refiner,
	"en":en_refiner,
	"zh":zh_refiner,
	"ja":ja_refiner
};
export function getRawRefiner(lang){
	return refinerByLang[lang];
}
export function getRawRefinerList(LangList) {
	return LangList.map(function(lang){
		return refinerByLang[lang];
	});
}