import {isIgnorableElement} from '../config/appConfig.js';

export function findAscendantNodeByAttribute(targetNode,attributeName,attributeValue) {
	if (targetNode.nodeName === "HTML" || targetNode.nodeName === 'BODY') {
		return null;
	}
	let node = targetNode.parentNode;
	while (node != null && node.nodeName !=='BODY') {
		if (node.getAttribute(attributeName) === attributeValue) {
			return node;
		}
		node = node.parentNode;
	}
	return null;
}

export function getGeneralTextElements(element) {
	//console.log(`[domNodeUtil.js] getTextElements, element.textContent = ${element.textContent}`);
	let textNodeList = [];
	travel(textNodeList, element);
	/*let planeTextArray = textNodeList.map(function(item){
		return item.textContent;
	});
	console.log(`[domNodeUtil.js] getTextElements, textNodeList = ${JSON.stringify(planeTextArray)}`);*/
	return {
		next: function () {
			return textNodeList.shift();
		},
		hasNext: function () {
			if (textNodeList.length == 0) {
				return false;
			} else {
				return true;
			} 
		}
	};
}

function travel(textNodeList, ele) {
	var nodeList = ele.childNodes; //get all child nodes

	for (var i = 0, cnt = nodeList.length; i < cnt; i++) {
		var itemNode = nodeList[i];
		if (itemNode.nodeType == Node.TEXT_NODE) {
			//textNodeList.push(itemNode);
			if (itemNode.textContent.replace(/\r|\n|\s/g,"") !== "") {
				textNodeList.push(itemNode);
			}
		} else if (itemNode.nodeType === Node.ELEMENT_NODE && !isIgnorableElement(itemNode)) { // element node  Node.ELEMENT_NODE
			//}else if(itemNode.nodeType == Node.ELEMENT_NODE ){ // element node  Node.ELEMENT_NODE
			//console.log(`[domeNodeUtil] element node, ${itemNode.nodeName}`);
			travel(textNodeList, itemNode);
		}
	}
}

export default {
	getGeneralTextElements,
	findAscendantNodeByAttribute,
}